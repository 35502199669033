import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { signOut, resetEvomPasswordRequest } from 'actions/Auth';

const UserInfo = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(({ auth }) => auth.authUser);
  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = event => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const getUserName = () => {
    if (authUser) {
      return authUser.login;
    }
    return '';
  };

  const handleResetPassword = () => {
    if (authUser && window.confirm('Um email para alteração de senha será enviado para ' + authUser.email + '.')) {
      dispatch(resetEvomPasswordRequest(authUser.email));
      handleLogOut();
    }
  };

  const handleLogOut = () => {
    dispatch(signOut());
  };

  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Avatar
        alt="..."
        src={require('assets/images/generic_avatar.png')}
        className="user-avatar "
      />
      <div className="user-detail">
        <h4 className="user-name d-flex" onClick={handleClick}>
          <span className="text-truncate" style={{ fontSize: '8pt', textTransform: 'lowercase' }}>{getUserName()}</span>
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
        </h4>
      </div>
      <Menu className="user-info"
            id="simple-menu"
            anchorEl={anchorE1}
            open={open}
            onClose={handleRequestClose}
            PaperProps={{
              style: {
                minWidth: 120,
                paddingTop: 0,
                paddingBottom: 0
              }
            }}
      >
        <MenuItem onClick={() => {
          handleRequestClose();
          handleResetPassword();
        }}>
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
          Redefinir senha
        </MenuItem>
        <MenuItem onClick={() => {
          handleRequestClose();
          handleLogOut();
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>
          Sair
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserInfo;
