import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetEvomPassword, clearResetEvomPwdResult } from 'actions/Auth';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory, useLocation } from 'react-router-dom';

const ResetEvomPassword = () => {
  const location = useLocation();
  const [resetKey, setResetKey] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const dispatch = useDispatch();
  const { loading } = useSelector(({ common }) => common);
  const [showPassword, setShowPassword] = useState(false);
  const resetEvomPwdResult = useSelector(({ auth }) => auth.resetEvomPwdResult);
  const history = useHistory();

  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const key = query.get('key');
    setResetKey(key);
  }, []);

  useEffect(() => {
    if (resetEvomPwdResult) {
      dispatch(clearResetEvomPwdResult());
      history.push('/');
    }
  }, [resetEvomPwdResult]);

  const handleClickShowPassword = () => {
    if (!showPassword) {
      setShowPassword(true);
    } else { setShowPassword(false); }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const doSubmit = (e) => {
    e.preventDefault();
    if (password && passwordConfirmation && password === passwordConfirmation) {
        dispatch(resetEvomPassword({ resetKey, newPassword: password }));
    } else {
        window.alert("Passwords must be equal.");
    }
  }

  return (
  <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">

    <form onSubmit={(e) => { doSubmit(e) }}>
      <div style={{backgroundColor: '#3f51b5', padding: '10px', borderRadius: '20px', border: '50px #3f51b5'}}>
        <img src={require("assets/images/evom_white_logo.png")}
             width="100%"
             alt="EvomPass" title="EvomPass" />
      </div>
      <fieldset>
        <TextField
            type={showPassword ? 'text' : 'password'}
            label="Senha Nova"
            fullWidth
            onChange={(event) => { setPassword(event.target.value) }}
            defaultValue={password}
            margin="normal"
            className="mt-1 my-sm-3"
            required
            InputProps={{endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }}
        />

        <TextField
            type={'password'}
            label="Confirmação da Senha Nova"
            fullWidth
            onChange={(event) => { setPasswordConfirmation(event.target.value) }}
            defaultValue={passwordConfirmation}
            margin="normal"
            className="mt-1 my-sm-3"
            required
        />

        <div className="mb-3 d-flex align-items-center justify-content-between">
          <Button type="submit" variant="contained" color="primary">
            Confirmar
          </Button>
        </div>

      </fieldset>
    </form>

    {
      loading &&
      <div className="loader-view">
        <CircularProgress />
      </div>
    }
  </div>
  );
}

export default ResetEvomPassword;
