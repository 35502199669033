import {
    SIGN_OUT,
    GET_POS_TRANSACTIONS,
  } from "../constants/ActionTypes";
  
  const INIT_STATE = {
    posTransactions: {
      data: [],
      count: 0
    },
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case SIGN_OUT: {
        return INIT_STATE;
      }
  
      case GET_POS_TRANSACTIONS: {
        return {
          ...state,
          posTransactions: action.payload,
        };
      }
  
      default:
        return state;
    }
  }