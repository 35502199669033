import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'actions/Auth';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation } from 'react-query';
import evompassApi from 'util/Api';
import PhoneNumberInput from 'app/routes/components/PhoneNumberInput';
import { EMAIL_REGEX_VALIDATION, EMPLOYER_TYPE, PHONE_REGEX_VALIDATION } from 'util/EvomConstants';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { useGetPaymentArrangementInfo } from 'hooks/api/paymentArrangement';
import LoadingView from 'app/routes/components/LoadingView';
import Page404 from 'app/routes/Page404';
import { toast } from 'react-toastify';
import { DatePicker } from '@material-ui/pickers';
import EvomUtils from 'util/EvomUtils';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const SignUp = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(({ common }) => common);
  const { paymentArrangementUrlSlug } = useParams();
  const {
    data: paymentArrangementInfo,
    isLoading: isPaymentArrangementInfoLoading,
    isError: isPaymentArrangementInfoError,
  } = useGetPaymentArrangementInfo(paymentArrangementUrlSlug);
  const { mutate, isLoading: isSignUpLoading, isSuccess: isSignUpSuccess } = useMutation(data => evompassApi.post(`/sign-up`, data));
  const { register, handleSubmit, errors, control, watch } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const employerType = watch('employerType', EMPLOYER_TYPE.INDIVIDUAL);
  const [email, setEmail] = useState(null);

  const signUp = ({ employerType, name, email, phoneNumber, cpf, cnpj, incorporationDate }) => {
    setEmail(email);

    const userData = {
      paymentArrangementUrlSlug: paymentArrangementUrlSlug,
      name: name,
      email: email,
      phoneNumber: EvomUtils.clearPhoneNumberKeepingPlusSign(phoneNumber),
      employerType: employerType,
      cnpj: employerType === EMPLOYER_TYPE.INDIVIDUAL ? EvomUtils.clearCpfTxt(cpf) : EvomUtils.clearCnpjTxt(cnpj),
      incorporationDate: incorporationDate.toISOString(),
    };

    mutate(userData, {
      onSuccess: () => dispatch(signOut()),
      onError: error => {
        console.log(error);
        if (error.response?.data?.message === 'error.validation') {
          for (const validationError of error.response.data.errors) {
            if (validationError === 'cpf/cnpj inválido') toast.error(employerType === EMPLOYER_TYPE.INDIVIDUAL ? 'cpf inválido' : 'cnpj inválido');
            else toast.error(validationError);
          }
        } else if (error.response?.data?.message === 'company.already.created') {
          toast.error(employerType === EMPLOYER_TYPE.INDIVIDUAL ? 'cpf já utilizado' : 'cnpj já utilizado');
        }  else if (error.response?.data?.message === 'user.login.already.used') {
          toast.error('E-mail já utilizado');
        } else {
          toast.error('Erro ao realizar cadastro, tente novamente mais tarde!');
        }
      }
    });
  }

  if (isPaymentArrangementInfoLoading) return (
    <LoadingView/>
  );

  if (isPaymentArrangementInfoError || !paymentArrangementInfo.allowEmployerSelfRegistration) return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <Page404 showGoBackButton={false}/>
    </div>
  );

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex flex-column align-items-center justify-content-center">
          <img
            alt="Logo"
            width="100%"
            title={paymentArrangementInfo.merchantName}
            src={paymentArrangementInfo.logoUrl}
          />

          <Typography variant="body1" className="mt-3" style={{ color: 'white' }}>
            Já possui uma conta? <Link to={`/${paymentArrangementUrlSlug}/entrar`} style={{ color: 'white' }}>Entrar</Link>
          </Typography>
        </div>

        <div className="app-login-content">
          <div className="app-login-header">
            <h1 style={{ color: '#3f51b5' }}>
              {paymentArrangementInfo.merchantName}
            </h1>
          </div>

          {
            isSignUpSuccess ?
            <div className="d-flex align-items-center justify-content-center" style={{ marginTop: '80px' }}>
              <CheckCircleOutlineIcon style={{ color: '#28a745', fontSize: '75px' }} className="mr-3"/>
              <Typography variant="body1">
                Conta criada com sucesso! Defina sua senha a partir do link enviado para <span className="font-weight-bold">{email}</span>
              </Typography>
            </div> :
              <>
                <div className="mb-4">
                  <Typography variant="body1">
                    Gerencie o vale transporte dos seus funcionários com mais facilidade!
                  </Typography>
                </div>

                <div className="app-login-form">
                  <form onSubmit={handleSubmit(signUp)}>
                    <Controller
                      render={
                        ({ value, onChange }) => (
                          <FormControl fullWidth margin="normal" className="mt-0 mb-2" error={!!errors.employerType}>
                            <InputLabel id="employerType-label" error={!!errors.employerType}>
                              Tipo de empregador
                            </InputLabel>

                            <Select
                              labelId="employerType-label"
                              id="employerType"
                              name="employerType"
                              value={value}
                              onChange={e => onChange(e.target.value)}
                              MenuProps={{ getContentAnchorEl: () => null, }}
                              error={!!errors.employerType}
                            >
                              <MenuItem value={EMPLOYER_TYPE.INDIVIDUAL}>Pessoa física</MenuItem>
                              <MenuItem value={EMPLOYER_TYPE.COMPANY}>Pessoa jurídica</MenuItem>
                            </Select>

                            {!!errors.employerType && <FormHelperText>Tipo de empregador</FormHelperText>}
                          </FormControl>
                        )
                      }
                      name="employerType"
                      control={control}
                      defaultValue="INDIVIDUAL"
                      rules={{ required: true }}
                    />

                    <TextField
                      fullWidth
                      className="mt-0 mb-2"
                      margin="normal"
                      id="name"
                      label={employerType === EMPLOYER_TYPE.INDIVIDUAL ? 'Nome' : 'Razão social'}
                      name="name"
                      type="text"
                      color="primary"
                      inputRef={register({ required: true })}
                      error={!!errors.name}
                      helperText={
                        errors.name ?
                          employerType === EMPLOYER_TYPE.INDIVIDUAL ?
                            'Nome obrigatório' :
                            'Razão social obrigatória' :
                          null
                      }
                    />

                    <TextField
                      fullWidth
                      className="mt-0 mb-2"
                      margin="normal"
                      id="email"
                      label="E-mail"
                      name="email"
                      type="text"
                      color="primary"
                      inputRef={register({ required: true, pattern: EMAIL_REGEX_VALIDATION })}
                      error={!!errors.email}
                      helperText={
                        errors.email?.type === 'required' ?
                          'E-mail obrigatório' :
                          errors.email?.type === 'pattern' ?
                            'E-mail inválido' :
                            null
                      }
                    />

                    <PhoneNumberInput
                      fullWidth
                      margin="dense"
                      className="mt-0 mb-2"
                      color="primary"
                      id="phoneNumber"
                      name="phoneNumber"
                      label="Telefone"
                      type="text"
                      inputRef={register({ required: true, pattern: PHONE_REGEX_VALIDATION })}
                      error={!!errors.phoneNumber}
                      helperText={
                        errors.phoneNumber?.type === 'required' ?
                          'Telefone obrigatório' :
                          errors.phoneNumber?.type === 'pattern' ?
                            'Telefone inválido' :
                            null
                      }
                    />

                    {
                      employerType === EMPLOYER_TYPE.INDIVIDUAL &&
                      <InputMask mask="999.999.999-99" maskChar="">
                        {
                          renderedInputComponentProps =>
                            <TextField
                              {...renderedInputComponentProps}
                              fullWidth
                              margin="normal"
                              className="mt-0 mb-2"
                              color="primary"
                              id="cpf"
                              name="cpf"
                              label="CPF"
                              type="text"
                              inputRef={register({ required: true, validate: cpf => cpfValidator.isValid(cpf) })}
                              error={!!errors.cpf}
                              helperText={
                                errors.cpf?.type === 'required' ?
                                  'CPF obrigatório' :
                                  errors.cpf?.type === 'validate' ?
                                    'CPF inválido' :
                                    null
                              }
                            />
                        }
                      </InputMask>
                    }

                    {
                      employerType === EMPLOYER_TYPE.COMPANY &&
                      <InputMask mask="99.999.999/9999-99" maskChar="">
                        {
                          renderedInputComponentProps =>
                            <TextField
                              {...renderedInputComponentProps}
                              fullWidth
                              margin="normal"
                              className="mt-0 mb-2"
                              color="primary"
                              id="cnpj"
                              name="cnpj"
                              label="CNPJ"
                              type="text"
                              inputRef={register({ required: true, validate: cnpj => cnpjValidator.isValid(cnpj) })}
                              error={!!errors.cnpj}
                              helperText={
                                errors.cnpj?.type === 'required' ?
                                  'CNPJ obrigatório' :
                                  errors.cnpj?.type === 'validate' ?
                                    'CNPJ inválido' :
                                    null
                              }
                            />
                        }
                      </InputMask>
                    }

                    <Controller
                      render={
                        ({ value, onChange, onBlur }) => (
                          <DatePicker
                            autoOk
                            fullWidth
                            ampm={false}
                            disableFuture
                            variant="inline"
                            format="DD/MM/yyyy"
                            margin="normal"
                            className="mt-0 mb-2"
                            label={employerType === EMPLOYER_TYPE.INDIVIDUAL ? 'Data de nascimento' : 'Data de abertura da empresa'}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={!!errors.incorporationDate}
                            helperText={
                              !!errors.incorporationDate ?
                                employerType === EMPLOYER_TYPE.INDIVIDUAL ?
                                  'Data de nascimento obrigatória' :
                                  'Data de abertura da empresa obrigatória' :
                                null
                            }
                          />
                        )
                      }
                      control={control}
                      name="incorporationDate"
                      defaultValue={null}
                      rules={{ required: true }}
                    />

                    <div className="mb-3 d-flex align-items-center justify-content-end">
                      <Button variant="contained" type="submit" color="primary" disabled={isSignUpLoading || loading}>
                        {(isSignUpLoading || loading) &&
                        <CircularProgress size={20} className="mr-2" color="primary"/>} Cadastrar
                      </Button>
                    </div>
                  </form>
                </div>
              </>
          }
        </div>
      </div>
    </div>
  );
};

export default SignUp;
