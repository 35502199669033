import {
  SIGN_OUT,
  GET_SYSTEM_PROPERTY,
  GET_ENABLE_DISCOUNT_POLICY_SYSTEM_PROPERTIES,
} from "../constants/ActionTypes";

const INIT_STATE = {
  systemProperties: {},
  enableDiscountPolicy: {
    enableDiscountPolicyFinancial: true,
    enableDiscountPolicyFare: true,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_SYSTEM_PROPERTY: {
      return {
        ...state,
        systemProperties: {
          ...state.systemProperties,
          [action.payload.systemProperty]: action.payload.value,
        },
      };
    }

    case GET_ENABLE_DISCOUNT_POLICY_SYSTEM_PROPERTIES: {
      return {
        ...state,
        enableDiscountPolicy: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
