import {
  SIGN_OUT,
  GET_COMPANIES_SIMPLE_LIST,
  GET_EVOM_ACCOUNTS_SIMPLE_LIST,
} from "../constants/ActionTypes";

const INIT_STATE = {
  companies: [],
  evomAccounts: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_COMPANIES_SIMPLE_LIST: {
      return {
        ...state,
        companies: action.payload,
      };
    }

    case GET_EVOM_ACCOUNTS_SIMPLE_LIST: {
      return {
        ...state,
        evomAccounts: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
