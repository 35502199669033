import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  GET_COMPANY_INVOICES,
  GET_COMPANY_INVOICES_PENDING,
  DEACTIVATE_INVOICE,
  SHOW_DEACTIVATE_INVOICE_DIALOG,
  HIDE_DEACTIVATE_INVOICE_DIALOG,
  SHOW_IMPORT_INVOICE_RECEIPT_ATTACHMENT_DIALOG,
  HIDE_IMPORT_INVOICE_RECEIPT_ATTACHMENT_DIALOG,
} from 'constants/ActionTypes';
import axios from 'util/Api';
import { toast } from 'react-toastify';
import formatErrorResponse from 'util/EvomErrorsUtils';

const companyInvoiceBaseEndpoint = 'top-up-invoices';

export const getCompanyInvoices = (page, size, sort, filter) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.get(`${companyInvoiceBaseEndpoint}?page=${page}&size=${size}&sort=${sort}&${filter}`)
      .then(res => {
        if (res.data) {
          const totalCount = res.headers['x-total-count'] ? parseInt(res.headers['x-total-count'], 10) : 0;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_COMPANY_INVOICES, payload: { data: res.data, count: totalCount } });
        } else {
          console.log('Error****:', res);
          dispatch({ type: FETCH_ERROR, payload: res.error });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(error => {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

  export const getCompanyInvoicesPending = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.get(`${companyInvoiceBaseEndpoint}?page=0&size=10&sort=createdDate,desc&status.in=SETTLED&paymentStatus.in=PENDING&`)
      .then(res => {
        if (res.data) {
          const totalCount = res.headers['x-total-count'] ? parseInt(res.headers['x-total-count'], 10) : 0;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_COMPANY_INVOICES_PENDING, payload: { data: res.data, count: totalCount } });
        } else {
          console.log('Error****:', res);
          dispatch({ type: FETCH_ERROR, payload: res.error });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(error => {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const hideDeactivateInvoiceDialog = () => {
  return { type: HIDE_DEACTIVATE_INVOICE_DIALOG };
};

export const showDeactivateInvoiceDialog = invoiceId => {
  return {
    type: SHOW_DEACTIVATE_INVOICE_DIALOG,
    payload: invoiceId,
  };
};

export const deactivateInvoice = invoiceId => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.patch(`top-up-invoices/${invoiceId}/cancel`)
      .then(res => {
        if (res.data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: DEACTIVATE_INVOICE, payload: res.data });
          dispatch({ type: HIDE_DEACTIVATE_INVOICE_DIALOG });
          toast.success('Fatura desativada');
        } else {
          console.log('Error****:', res.data);
          dispatch({ type: FETCH_ERROR, payload: res.data.error });
          dispatch({ type: HIDE_MESSAGE });
        }
      })
      .catch(error => {
        console.log('Error****:', error);
        toast.error(formatErrorResponse(error));
        dispatch({ type: FETCH_ERROR });
      });
  };
};

export const showImportInvoiceReceiptAttachmentDialog = () => {
  return { type: SHOW_IMPORT_INVOICE_RECEIPT_ATTACHMENT_DIALOG }
};

export const hideImportInvoiceReceiptAttachmentDialog = () => {
  return { type: HIDE_IMPORT_INVOICE_RECEIPT_ATTACHMENT_DIALOG }
};