import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_EVOM_ACCOUNT_WALLETS,
} from 'constants/ActionTypes';
import axios from 'util/Api';
import { toast } from 'react-toastify';
import formatErrorResponse from 'util/EvomErrorsUtils';

export const getEvomAccountWallets = (page, size, sort, filter) => async dispatch => {
  dispatch({ type: FETCH_START });
  try {
    const res = await axios.get(`evom-accounts?page=${page}&size=${size}&sort=${sort}&${filter}`);
    const totalCount = res.headers['x-total-count'] ? parseInt(res.headers['x-total-count'], 10) : 0;
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: GET_EVOM_ACCOUNT_WALLETS, payload: { data: res.data, count: totalCount } });
  } catch (error) {
    console.log('Error****:', error);
    toast.error(formatErrorResponse(error));
    dispatch({ type: FETCH_ERROR });
  }
};
