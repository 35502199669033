import {
  SIGN_OUT,
  GET_EVOM_ACCOUNT_WALLETS,
} from 'constants/ActionTypes';

const INIT_STATE = {
  evomAccountWallets: {
    data: [],
    count: 0
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_EVOM_ACCOUNT_WALLETS: {
      return {
        ...state,
        evomAccountWallets: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
