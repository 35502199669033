import {
  SIGN_OUT,
  GET_AGENCY_USERS,
  CREATE_AGENCY_USER,
  UPDATE_AGENCY_USER,
  SHOW_UPDATE_AGENCY_USER_FORM,
  SHOW_CREATE_AGENCY_USER_FORM,
  SHOW_RESET_AGENCY_USER_PASSWORD_DIALOG,
  HIDE_UPDATE_AGENCY_USER_FORM,
  HIDE_CREATE_AGENCY_USER_FORM,
  HIDE_RESET_AGENCY_USER_PASSWORD_DIALOG,

} from 'constants/ActionTypes';

const INIT_STATE = {
  users: {
    data: [],
    count: 0
  },
  userUpdating: {
    isUpdatingAnUser: false,
    currentUpdatingUser: null
  },
  userCreation: {
    isCreatingAnUser: false,
  },
  passwordReset: {
    showResetUserPasswordDialog: false,
    currentUserResetingPassword: null
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_AGENCY_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case CREATE_AGENCY_USER: {
      const newUser = action.payload;

      return {
        ...state,
        users: {
          ...state.users,
          data: [
            newUser,
            ...state.users.data
          ],
          count: state.users.count + 1
        }
      };
    }

    case UPDATE_AGENCY_USER: {
      const updatedUser = action.payload;

      const newUsers = updatedUser !== null ?
        state.users.data.map(user => user.id === updatedUser.id ? updatedUser : user) :
        state.users.data.filter(user => user.id === state.userUpdating.currentUpdatingUser.id ? updatedUser : user);

      return {
        ...state,
        users: {
          ...state.users,
          data: newUsers,
        }
      };
    }

    case SHOW_UPDATE_AGENCY_USER_FORM: {
      const userId = action.payload;
      const currentUpdatingUser = state.users.data.find(user => user.id === userId);

      return {
        ...state,
        userUpdating: {
          isUpdatingAnUser: true,
          currentUpdatingUser: currentUpdatingUser
        },
      };
    }

    case SHOW_CREATE_AGENCY_USER_FORM: {
      return {
        ...state,
        userCreation: {
          isCreatingAnUser: true,
        },
      };
    }

    case HIDE_UPDATE_AGENCY_USER_FORM: {
      return {
        ...state,
        userUpdating: {
          isUpdatingAnUser: false,
          currentUpdatingUser: null
        },
      };
    }

    case HIDE_CREATE_AGENCY_USER_FORM: {
      return {
        ...state,
        userCreation: {
          isCreatingAnUser: false,
        },
      };
    }

    case SHOW_RESET_AGENCY_USER_PASSWORD_DIALOG: {
      const user = state.users.data.find(user => user.id === action.payload);

      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          showResetUserPasswordDialog: true,
          currentUserResetingPassword: user,
        }
      }
    }

    case HIDE_RESET_AGENCY_USER_PASSWORD_DIALOG: {
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          showResetUserPasswordDialog: false,
          currentUserResetingPassword: null,
        }
      }
    }

    case SIGN_OUT: {
      return INIT_STATE;
    }

    default: {
      return state;
    }

  }
}