import evompassApi from 'util/Api';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import EvomUtils from 'util/EvomUtils';

export const useGetPaymentArrangementSummary = (startDatetime, endDatetime) => useQuery(
  ['paymentArrangementSummary', startDatetime, endDatetime],
  () => evompassApi.get(`dashboard/payment-arrangement/summary?startDatetime=${startDatetime}&endDatetime=${endDatetime}`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      console.log('res do erro', error.response);
      if(error.response?.data?.detail){
            toast.error(error.response?.data?.detail);
      } else if(!EvomUtils.isSystemUnavailable(error)) {
        // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      } else{
            toast.error("Não foi possível carregar os dados do arranjo de pagamento");
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetPaymentArrangementTransactionsByDay = (startDatetime, endDatetime) => useQuery(
  ['paymentArrangementTransactionsByDay', startDatetime, endDatetime],
  () => evompassApi.get(`dashboard/payment-arrangement/transactions-by-day?startDatetime=${startDatetime}&endDatetime=${endDatetime}`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar as transações por dia');
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetPaymentArrangementActiveWallets = (startDatetime, endDatetime) => useQuery(
  ['activeWallets', startDatetime, endDatetime],
  () => evompassApi.get(`dashboard/register/active-wallets?startDatetime=${startDatetime}&endDatetime=${endDatetime}`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar as contas ativas');
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetPaymentArrangementActiveCompanies = () => useQuery(
  ['activeCompanies'],
  () => evompassApi.get(`dashboard/register/active-companies`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar os empregadores ativos');
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetPaymentArrangementWalletsByType = (startDatetime, endDatetime) => useQuery(
  ['paymentArrangementWalletsByType', startDatetime, endDatetime],
  () => evompassApi.get(`dashboard/payment-arrangement/wallets-by-type?startDatetime=${startDatetime}&endDatetime=${endDatetime}`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar as carteiras por tipo');
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetCountIdentificationMethodByType = (startDatetime, endDatetime) => useQuery(
  ['countIdentificationMethodByType', startDatetime, endDatetime],
  () => evompassApi.get(`dashboard/count-identification-method-by-type?startDatetime=${startDatetime}&endDatetime=${endDatetime}`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar a quantia de métodos de identificação por tipo');
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetPaymentArrangementTopUpInvoicesCreditsByDate = (startDatetime, endDatetime) => useQuery(
  ['paymentArrangementTopUpInvoicesCreditsByDate', startDatetime, endDatetime],
  () => evompassApi.get(`dashboard/payment-arrangement/credits/top-up-invoice?startDatetime=${startDatetime}&endDatetime=${endDatetime}`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar os créditos por faturas por mês');
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetPaymentArrangementPixCreditsByDate = (startDatetime, endDatetime) => useQuery(
  ['paymentArrangementPixCreditsByDate', startDatetime, endDatetime],
  () => evompassApi.get(`dashboard/payment-arrangement/credits/pix?startDatetime=${startDatetime}&endDatetime=${endDatetime}`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar os créditos por PIX por mês');
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetPaymentArrangementEvomPdvCreditsByDate = (startDatetime, endDatetime) => useQuery(
  ['paymentArrangementEvomPdvCreditsByDate', startDatetime, endDatetime],
  () => evompassApi.get(`dashboard/payment-arrangement/credits/evom-pdv?startDatetime=${startDatetime}&endDatetime=${endDatetime}`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar os créditos por Evom PDV por mês');
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetPaymentArrangementCreditCardCreditsByDate = (startDatetime, endDatetime) => useQuery(
  ['paymentArrangementCreditCardCreditsByDate', startDatetime, endDatetime],
  () => evompassApi.get(`dashboard/payment-arrangement/credits/credit-card?startDatetime=${startDatetime}&endDatetime=${endDatetime}`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar os créditos por cartão de crédito por mês');
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetPaymentArrangementInfo = paymentArrangementUrlSlug => useQuery(
  ['paymentArrangementInfo', paymentArrangementUrlSlug],
  () => evompassApi.get(`payment-arrangement-info/${paymentArrangementUrlSlug}`).then(res => res.data),
  { onError: console.log, enabled: !!paymentArrangementUrlSlug, refetchOnWindowFocus: false }
);

export const useGetAvailableBalance = () => useQuery(
  ['availableBalance'],
  () => evompassApi.get(`dashboard/real-time/available-balance`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar os dados do arranjo de pagamento');
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetCreditAndTopUpInvoicesAmountPending = () => useQuery(
  ['creditAndTopUpInvoicesAmountPending'],
  () => evompassApi.get(`dashboard/real-time/pending-credit-and-top-up-invoices-amount`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar os dados do arranjo de pagamento');
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetPendingPixTotalAmount = () => useQuery(
  ['pendingPixTotalAmount'],
  () => evompassApi.get(`dashboard/real-time/pending-pix-total-amount`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar os dados do arranjo de pagamento');
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetReleasedCreditPendingPayment = () => useQuery(
  ['releasedCreditPendingPayment'],
  () => evompassApi.get(`dashboard/real-time/top-up-invoices-pending-payment-total-amount`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar os dados do arranjo de pagamento');
      }
    },
    refetchOnWindowFocus: false,
  }
);

export const useGetCreditReleasedWithOverduePayment = () => useQuery(
  ['creditReleasedWithOverduePayment'],
  () => evompassApi.get(`dashboard/real-time/top-up-invoices-overdue-payment-total-amount`).then(res => res.data),
  {
    onError: error => {
      console.log(error);
      // desabilitando toast por conta do CORS error - hotfix dos dashboards 23/08/2023
      if(!EvomUtils.isSystemUnavailable(error)) {
        toast.error('Não foi possível carregar os dados do arranjo de pagamento');
      }
    },
    refetchOnWindowFocus: false,
  }
);
