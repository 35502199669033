import {
  SIGN_OUT,
  GET_EXPENSES
} from "../constants/ActionTypes";

const INIT_STATE = {
  expenses: {
    data: [],
    count: 0
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_EXPENSES: {
      return {
        ...state,
        expenses: action.payload,
      };
    }

    default:
      return state;
  }
}
