import {
  SIGN_OUT,
  GET_PAYMENT_ARRANGEMENT_INVOICES,
  GET_A_PAYMENT_ARRANGEMENT_INVOICE,
  GET_PENDING_PAYMENT_ARRANGEMENT_INVOICES,
  UPDATE_PAYMENT_ARRANGEMENT_INVOICE,
  SHOW_INVOICE_SETTLEMENT_AND_PAYMENT_DIALOG,
  HIDE_INVOICE_SETTLEMENT_AND_PAYMENT_DIALOG,
  SHOW_INVOICE_PAYMENT_DIALOG,
  HIDE_INVOICE_PAYMENT_DIALOG,
  SHOW_INVOICE_SETTLEMENT_DIALOG,
  HIDE_INVOICE_SETTLEMENT_DIALOG,
  SHOW_REVIEW_RECEIPT_DIALOG,
  HIDE_REVIEW_RECEIPT_DIALOG,
} from 'constants/ActionTypes';

const INIT_STATE = {
  invoices: {
    data: [],
    count: 0
  },
  invoicesDetails: {},
  numberOfPendingInvoices: 0,
  currentSettlingAndPayingInvoice: null,
  currentSettlingInvoice: null,
  currentPayingInvoice: null,
  receiptReview: {
    isShowingReviewReceipt: false,
    currentReviewingInvoiceUuid: null,
    optionals: {},
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_PAYMENT_ARRANGEMENT_INVOICES: {
      return {
        ...state,
        invoices: action.payload,
      };
    }

    case GET_A_PAYMENT_ARRANGEMENT_INVOICE: {
      const invoiceUuid = action.payload.invoiceUuid;
      const data = action.payload.data;
      return {
        ...state,
        invoicesDetails: {
          ...state.invoicesDetails,
          [invoiceUuid]: data,
        },
      };
    }

    case GET_PENDING_PAYMENT_ARRANGEMENT_INVOICES: {
      return {
        ...state,
        numberOfPendingInvoices: action.payload,
      };
    }

    case UPDATE_PAYMENT_ARRANGEMENT_INVOICE: {
      const updatedInvoice = action.payload;
      const updatedInvoices = state.invoices.data
        .map(invoice => invoice.uuid === updatedInvoice.uuid ? updatedInvoice : invoice);

      return {
        ...state,
        invoices: {
          ...state.invoices,
          data: updatedInvoices,
        }
      };
    }

    case SHOW_INVOICE_SETTLEMENT_AND_PAYMENT_DIALOG: {
      const invoice = state.invoices.data.find(invoice => invoice.uuid === action.payload);

      return {
        ...state,
        currentSettlingAndPayingInvoice: invoice,
      };
    }

    case HIDE_INVOICE_SETTLEMENT_AND_PAYMENT_DIALOG: {
      return {
        ...state,
        currentSettlingAndPayingInvoice: null,
        numberOfPendingInvoices: 0,
      };
    }

    case SHOW_INVOICE_SETTLEMENT_DIALOG: {
      const invoice = state.invoices.data.find(invoice => invoice.uuid === action.payload.invoiceUuid);
      const showDebitMessage = action.payload.showDebitMessage?.data > 0;

      return {
        ...state,
        currentSettlingInvoice: {
          invoice: invoice,
          showDebitMessage: showDebitMessage
        },
      };
    }

    case HIDE_INVOICE_SETTLEMENT_DIALOG: {
      return {
        ...state,
        currentSettlingInvoice: null,
        numberOfPendingInvoices: 0,
      };
    }

    case SHOW_INVOICE_PAYMENT_DIALOG: {
      const invoice = state.invoices.data.find(invoice => invoice.uuid === action.payload);

      return {
        ...state,
        currentPayingInvoice: invoice,
      };
    }

    case HIDE_INVOICE_PAYMENT_DIALOG: {
      return {
        ...state,
        currentPayingInvoice: null,
        numberOfPendingInvoices: 0,
      };
    }

    case SHOW_REVIEW_RECEIPT_DIALOG: {
      return {
        ...state,
        receiptReview: {
          ...state.receiptReview,
          isShowingReviewReceipt: true,
          currentReviewingInvoiceUuid: action.payload.invoiceUuid,
          optionals: {
            ...action.payload.optionals
          },
        }
      };
    }

    case HIDE_REVIEW_RECEIPT_DIALOG: {
      return {
        ...state,
        receiptReview: {
          ...state.receiptReview,
          isShowingReviewReceipt: false,
          currentReviewingInvoiceUuid: null,
        }
      };
    }

    default:
      return state;
  }
}
