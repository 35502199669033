import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SIGN_IN,
  SIGN_OUT,
  RESET_EVOM_PWD_RESULT,
  SET_ACTIVE_COMPANY,
  GET_ACTIVE_COMPANY_DATA,
  SET_ACTIVE_PAYMENT_ARRANGEMENT,
  GET_FRESHWORKS_CONFIGURATION,
} from 'constants/ActionTypes';
import { queryClient } from 'MainApp';
import axios from 'util/Api';
import { toast } from 'react-toastify';
import formatErrorResponse from 'util/EvomErrorsUtils';
import { ROLE_AUTHORITIES } from 'util/EvomConstants';

export const signIn = ({ email, password }) => async dispatch => {
  dispatch({ type: FETCH_START });
  try {
    const { data: { id_token: token } } = await axios.post('auth', { username: email, password: password, rememberMe: false, });
    const { data: userResponse } = await axios.get('account', { headers: { Authorization: `Bearer ${token}` } });

    const isCompanyUser = userResponse.authorities.some(authority => authority === ROLE_AUTHORITIES.ROLE_COMPANY_USER);
    const isPaymentArrangementUser = userResponse.authorities.some(authority => authority === ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN);
    const isEmployerUser = userResponse.authorities.some(authority => authority === ROLE_AUTHORITIES.ROLE_EMPLOYER_USER);
    const isPOSAgencyUser = userResponse.authorities.some(authority => authority === ROLE_AUTHORITIES.ROLE_POS_AGENCY);

    const authUser = {
      ...userResponse,
      isCompanyUser: isCompanyUser,
      isPaymentArrangementUser: isPaymentArrangementUser,
      isEmployerUser: isEmployerUser,
      isCompanyOrEmployerUser: isCompanyUser || isEmployerUser,
      isPOSAgencyUser: isPOSAgencyUser,
    };

    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGN_IN, payload: { token, authUser, authUserDate: (new Date()).getTime() } });
  } catch (error) {
    console.log('Error****:', error);
    toast.error(formatErrorResponse(error));
    dispatch({ type: FETCH_ERROR });
  }
};

export const signOut = () => async dispatch => {
  dispatch({ type: SIGN_OUT });
  queryClient.clear();

  if (window.fcWidget) {
    try {
      await window.fcWidget.user.clear();
      window.fcWidget.destroy();
    } catch (error) {
      window.fcWidget.destroy();
    }
  }
};

export const userSignUp = ({ _name, _email, _password }) => {
  /* not required yet */
};

export const resetEvomPasswordRequest = email => async dispatch => {
  dispatch({ type: FETCH_START });
  try {
    await axios.post('account/reset-password/init', email);
    dispatch({ type: FETCH_SUCCESS });
  } catch (error) {
    console.log('Error****:', error);
    toast.error(formatErrorResponse(error));
    dispatch({ type: FETCH_ERROR });
  }
};

export const resetEvomPassword = ({ resetKey, newPassword }) => async dispatch => {
  const data = { key: resetKey, newPassword: newPassword };
  dispatch({ type: FETCH_START });
  try {
    await axios.post('account/reset-password/finish', data);
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: RESET_EVOM_PWD_RESULT, payload: true });
    dispatch(signOut());
  } catch (error) {
    console.log('Error****:', error);
    toast.error(formatErrorResponse(error));
    dispatch({ type: FETCH_ERROR });
    dispatch({ type: RESET_EVOM_PWD_RESULT, payload: false });
  }
};

export const clearResetEvomPwdResult = () => dispatch => {
  dispatch({ type: RESET_EVOM_PWD_RESULT, payload: false });
};

export const setActivePaymentArrangement = activePaymentArrangement => dispatch => {
  dispatch({ type: SET_ACTIVE_PAYMENT_ARRANGEMENT, payload: activePaymentArrangement });
};

export const setActiveCompany = activeCompany => dispatch => {
  dispatch({ type: SET_ACTIVE_COMPANY, payload: activeCompany });
};

export const getActiveCompanyData = activeCompanyId => async dispatch => {
  dispatch({ type: FETCH_START });
  try {
    const res = await axios.get(`companies/${activeCompanyId}`);
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: GET_ACTIVE_COMPANY_DATA, payload: res.data });
  } catch (error) {
    console.log('Error****:', error);
    toast.error(formatErrorResponse(error));
    dispatch({ type: FETCH_ERROR });
  }
};

export const getFreshworksConfiguration = () => async dispatch => {
  dispatch({ type: FETCH_START });
  try {
    const res = await axios.get(`freshworks-configuration`);
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: GET_FRESHWORKS_CONFIGURATION, payload: res.data });
  } catch (error) {
    console.log('Error****:', error);
    toast.error(formatErrorResponse(error));
    dispatch({ type: FETCH_ERROR });
  }
};