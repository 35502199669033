import axios from 'axios';
import { signOut } from 'actions/Auth';
import { store } from 'MainApp';
import { toast } from 'react-toastify';
import {
  STORAGE_ITEM_TOKEN,
  STORAGE_ITEM_USER,
  STORAGE_ITEM_ACTIVE_PAYMENT_ARRANGEMENT,
  STORAGE_ITEM_ACTIVE_COMPANY,
} from 'reducers/Auth';

const evompassApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-CLIENT-TIMEZONE': Intl.DateTimeFormat().resolvedOptions().timeZone ?? '',
  },
});

evompassApi.interceptors.request.use(
  request => {
    const jwtToken = localStorage.getItem(STORAGE_ITEM_TOKEN);
    const authUser = JSON.parse(localStorage.getItem(STORAGE_ITEM_USER));
    const activePaymentArrangement = JSON.parse(localStorage.getItem(STORAGE_ITEM_ACTIVE_PAYMENT_ARRANGEMENT));
    const activeCompany = JSON.parse(localStorage.getItem(STORAGE_ITEM_ACTIVE_COMPANY));

    if (jwtToken && !request.headers['Authorization'])
      request.headers['Authorization'] = `Bearer ${jwtToken}`;

    if (activePaymentArrangement !== null && activePaymentArrangement !== undefined && !request.headers['X-PAYMENT-ARRANGEMENT'])
      request.headers['X-PAYMENT-ARRANGEMENT'] = activePaymentArrangement.guiBrCode;
    else if (authUser?.paymentArrangementGuiBrCode && !request.headers['X-PAYMENT-ARRANGEMENT'] && !request.headers['X-PAYMENT-ARRANGEMENT'])
      request.headers['X-PAYMENT-ARRANGEMENT'] = authUser.paymentArrangementGuiBrCode;

    if (activeCompany?.companyId && !request.headers['X-COMPANY'])
      request.headers['X-COMPANY'] = activeCompany.companyId;

    return request;
  },
  error => Promise.reject(error)
);

evompassApi.interceptors.response.use(
  response => response,
  error => {
    if (error?.config?.url === 'auth') return Promise.reject(error);

    if (error?.response?.status === 401) {
      if (store.getState().auth.isLoggedIn) toast.error('Usuário não logado');
      store.dispatch(signOut());
    }

    return Promise.reject(error);
  }
);

export default evompassApi;
