import {
  SIGN_OUT,
  GET_COMPANY_STATEMENT,
} from "constants/ActionTypes";

const INIT_STATE = {
  companyStatement: {
    data: [],
    count: 0
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_STATEMENT: {
      return {
        ...state,
        companyStatement: action.payload,
      };
    }

    case SIGN_OUT: {
      return INIT_STATE;
    }

    default: {
      return state;
    }
  }
}
