import React from 'react';
import { CircularProgress } from '@material-ui/core';

const LoadingView = () => {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <CircularProgress size='6rem' thickness={1} />
    </div>
  );
};

export default LoadingView;
