import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  GET_SYSTEM_PROPERTY,
  GET_ENABLE_DISCOUNT_POLICY_SYSTEM_PROPERTIES,
} from 'constants/ActionTypes';
import axios from 'util/Api';

export const getSystemProperty = systemProperty => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.get(`payment-arrangement-system-properties/${systemProperty}`);
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_SYSTEM_PROPERTY, payload: { systemProperty: systemProperty, value: res.data } });
      } else {
        console.log('Error****:', res.data);
        dispatch({ type: FETCH_ERROR, payload: res.data.error });
        dispatch({ type: HIDE_MESSAGE });
      }
    } catch (error) {
      console.log('Error****:', error);
      dispatch({ type: FETCH_ERROR });
    }
  };
};

export const getEnableDiscountPolicySystemProperties = () => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    try {
      const res = await axios.get(`payment-arrangement-system-properties/discount-policy`);
      if (res.data) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_ENABLE_DISCOUNT_POLICY_SYSTEM_PROPERTIES, payload: res.data });
      } else {
        console.log('Error****:', res.data);
        dispatch({ type: FETCH_ERROR, payload: res.data.error });
        dispatch({ type: HIDE_MESSAGE });
      }
    } catch (error) {
      console.log('Error****:', error);
      dispatch({ type: FETCH_ERROR });
    }
  };
};
