import {
  SIGN_OUT,

  GET_COMPANY_SUMMARY,

  GET_TOP_UP_INVOICES_COUNT_BY_STATUS,
  GET_TOP_UP_INVOICES_COUNT_BY_DAY_AND_STATUS,

} from "../constants/ActionTypes";
import EvomUtils from 'util/EvomUtils';
import Moment from 'moment';

const INIT_STATE = {
  dashboard: {

    companySummary: {
      companyName: "",
      companyWalletsTotal: 0,
      companyWalletsTotalBalance: 0,
      authorizedGetOnsTotal: 0,
      authorizedGetOnsTotalAmount: 0,
      authorizedGetOnsDailyAvg: 0,
      lastTopUpInvoiceSettlement: "22/09/2020",
    },

    topUpInvoicesCountByStatus: [],
    topUpInvoicesCountByDayAndStatus: [],

  },
};

const fillNullTopUpInvoiceStatus = (topUpInvoicesCountByStatus) => {
  let result = [
    { "name": "PENDING", value: 0 },
    { "name": "SETTLED", value: 0 },
    { "name": "CANCELED", value: 0 },
  ];
  if (topUpInvoicesCountByStatus) {
    EvomUtils.mergeArraysByProperty(result, topUpInvoicesCountByStatus, 'name');
  }
  return result;
}

const formatAndSortTopUpInvoicesByDayAndStatus = (payload) => {
  if (!payload) {
    return payload;
  }
  return payload
    .map(entry => {
      return {
        ...entry,
        day: Moment(entry.day, ['YYYY-MM-DD'])
      }
    })
    .sort((a, b) => a.day - b.day)
    .map(entry => {
      return {
        ...entry,
        day: entry.day.format("DD/MM/YYYY")
      }
    });
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SIGN_OUT: {
      // console.log("Company SIGN_OUT");
      return INIT_STATE;
    }

    case GET_COMPANY_SUMMARY: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          companySummary: action.payload,
        },
      };
    }

    case GET_TOP_UP_INVOICES_COUNT_BY_STATUS: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          topUpInvoicesCountByStatus: fillNullTopUpInvoiceStatus(action.payload),
        },
      };
    }

    case GET_TOP_UP_INVOICES_COUNT_BY_DAY_AND_STATUS: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          topUpInvoicesCountByDayAndStatus: formatAndSortTopUpInvoicesByDayAndStatus(action.payload),
        },
      };
    }

    default:
      return state;

  }
}
