import {
  SIGN_OUT,
  GET_WALLET_REQUESTS,
  UPDATE_WALLET_REQUEST,
  SHOW_DENY_WALLET_FORM,
  HIDE_DENY_WALLET_FORM,
  SHOW_APPROVE_WALLET_FORM,
  HIDE_APPROVE_WALLET_FORM,
  LOAD_ATTACHMENT_FILE,
  SHOW_ATTACHMENT_DIALOG,
  HIDE_ATTACHMENT_DIALOG,
} from "../constants/ActionTypes";

const INIT_STATE = {
  walletRequests: {
    data: [],
    count: 0
  },
  denialFormData: {
    deny: false,
    applicationId: '',
    walletType: '',
    createdDate: ''
  },
  approvalFormData: {
    isApproving: false,
    applicationId: null,
  },
  attachmentView: {
    showAttachment: false,
    attachmentDataURL: '',
    walletParameterType: '',
    index: 0,
    applicationId: '',
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_WALLET_REQUESTS: {
      return {
        ...state,
        walletRequests: action.payload
      };
    }

    case UPDATE_WALLET_REQUEST: {
      const updatedWalletRequest = action.payload;
      const newWalletRequestsData = state.walletRequests.data.filter(
        walletRequest =>
          walletRequest.applicationId !== updatedWalletRequest.applicationId
      );

      return {
        ...state,
        walletRequests: {
          ...state.walletRequests,
          data: newWalletRequestsData,
          count: state.walletRequests.count - 1
        }
      };
    }

    case SHOW_DENY_WALLET_FORM: {
      return {
        ...state,
        denialFormData: action.payload
      };
    }

    case HIDE_DENY_WALLET_FORM: {
      return {
        ...state,
        denialFormData: {
          ...state.denialFormData,
          deny: false,
        }
      };
    }

    case SHOW_APPROVE_WALLET_FORM: {
      return {
        ...state,
        approvalFormData: {
          ...state.approvalFormData,
          isApproving: true,
          applicationId: action.payload,
        }
      }
    }

    case HIDE_APPROVE_WALLET_FORM: {
      return {
        ...state,
        approvalFormData: {
          ...state.approvalFormData,
          isApproving: false,
        }
      }
    }

    case SHOW_ATTACHMENT_DIALOG: {
      const { index, walletParameterType, applicationId } = action.payload;
      return {
        ...state,
        attachmentView: {
          ...state.attachmentView,
          showAttachment: true,
          index: index,
          walletParameterType: walletParameterType,
          applicationId: applicationId,
        }
      }
    }

    case LOAD_ATTACHMENT_FILE: {
      const { attachmentDataURL } = action.payload;

      return {
        ...state,
        attachmentView: {
          ...state.attachmentView,
          attachmentDataURL: attachmentDataURL
        }
      }
    }

    case HIDE_ATTACHMENT_DIALOG: {
      URL.revokeObjectURL(state.attachmentView.attachmentDataURL);

      return {
        ...state,
        attachmentView: {
          ...state.attachmentView,
          showAttachment: false,
          attachmentDataURL: '',
          walletParameterType: '',
          index: 0,
          applicationId: '',
        }
      }
    }

    default: {
      return state;
    }

  }
}
