import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import EvomUtils from 'util/EvomUtils';

export default () => {
  const { authUser, activeCompanyData } = useSelector(({ auth }) => auth);

  return (
    activeCompanyData?.taxPercentage &&
    authUser?.isCompanyUser ?
    <div className="d-flex justify-content-center flex-column mr-3">
      <Typography
        variant="caption"
        style={{ color: '#dedede' }}
      >
        Retenção de impostos
      </Typography>

      <Typography variant="subtitle2" align='center' className="font-weight-bold" style={{ color: 'white' }}>
        {EvomUtils.convertDecimalToPercentage(activeCompanyData?.taxPercentage, 2)}
      </Typography>
    </div> : null
  );
};