import {
  SIGN_IN,
  SIGN_OUT,
  RESET_EVOM_PWD_RESULT,
  SET_ACTIVE_PAYMENT_ARRANGEMENT,
  SET_ACTIVE_COMPANY,
  GET_ACTIVE_COMPANY_DATA,
  GET_FRESHWORKS_CONFIGURATION,
} from 'constants/ActionTypes';

export const STORAGE_VERSION = '2';
export const STORAGE_ITEM_VERSION = 'version';
export const STORAGE_ITEM_TOKEN = 'token';
export const STORAGE_ITEM_USER = 'user';
export const STORAGE_ITEM_USER_DATE = 'userDate';
export const STORAGE_ITEM_ACTIVE_PAYMENT_ARRANGEMENT = 'activePaymentArrangement';
export const STORAGE_ITEM_ACTIVE_COMPANY = 'activeCompany';

const clearLocalStorage = () => {
  localStorage.removeItem(STORAGE_ITEM_TOKEN);
  localStorage.removeItem(STORAGE_ITEM_USER);
  localStorage.removeItem(STORAGE_ITEM_USER_DATE);
  localStorage.removeItem(STORAGE_ITEM_ACTIVE_PAYMENT_ARRANGEMENT);
  localStorage.removeItem(STORAGE_ITEM_ACTIVE_COMPANY);
  localStorage.setItem(STORAGE_ITEM_VERSION, STORAGE_VERSION);
};

if (localStorage.getItem(STORAGE_ITEM_VERSION) !== STORAGE_VERSION) {
  clearLocalStorage();
}

const INIT_STATE = {
  isLoggedIn: false,
  token: localStorage.getItem(STORAGE_ITEM_TOKEN) ?? null,
  authUser: JSON.parse(localStorage.getItem(STORAGE_ITEM_USER)) ?? null,
  authUserDate: JSON.parse(localStorage.getItem(STORAGE_ITEM_USER_DATE)) ?? null,
  activePaymentArrangement: JSON.parse(localStorage.getItem(STORAGE_ITEM_ACTIVE_PAYMENT_ARRANGEMENT)) ?? null,
  activeCompany: JSON.parse(localStorage.getItem(STORAGE_ITEM_ACTIVE_COMPANY)) ?? null,
  activeCompanyData: null,
  freshworksConfiguration: null,
  resetEvomPwdResult: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_IN: {
      const { token, authUser, authUserDate } = action.payload;

      const activePaymentArrangement =
        state.activePaymentArrangement !== null ?
          state.activePaymentArrangement :
          authUser.isCompanyOrEmployerUser || authUser.isPOSAgencyUser ?
            authUser.paymentArrangements[0] :
            null;

      const activeCompany =
        state.activeCompany !== null ?
          state.activeCompany :
          authUser.isCompanyOrEmployerUser || authUser.isPOSAgencyUser ?
            activePaymentArrangement.companies[0] :
            null;

      localStorage.setItem(STORAGE_ITEM_TOKEN, token);
      localStorage.setItem(STORAGE_ITEM_USER, JSON.stringify(authUser));
      localStorage.setItem(STORAGE_ITEM_USER_DATE, authUserDate);
      localStorage.setItem(STORAGE_ITEM_ACTIVE_PAYMENT_ARRANGEMENT, JSON.stringify(activePaymentArrangement));
      localStorage.setItem(STORAGE_ITEM_ACTIVE_COMPANY, JSON.stringify(activeCompany));

      return {
        ...state,
        isLoggedIn: true,
        token: token,
        authUser: authUser,
        authUserDate: authUserDate,
        activePaymentArrangement: activePaymentArrangement,
        activeCompany: activeCompany,
      };
    }

    case SIGN_OUT: {
      clearLocalStorage();
      return {
        isLoggedIn: false,
        token: null,
        authUser: null,
        authUserDate: null,
        activePaymentArrangement: null,
        activeCompany: null,
        freshworksConfiguration: null,
        resetEvomPwdResult: false,
      };
    }

    case RESET_EVOM_PWD_RESULT: {
      return {
        ...state,
        resetEvomPwdResult: action.payload,
      };
    }

    case SET_ACTIVE_PAYMENT_ARRANGEMENT: {
      const activePaymentArrangement = action.payload;
      const activeCompany = activePaymentArrangement.companies[0];

      localStorage.setItem(STORAGE_ITEM_ACTIVE_PAYMENT_ARRANGEMENT, JSON.stringify(activePaymentArrangement));
      localStorage.setItem(STORAGE_ITEM_ACTIVE_COMPANY, JSON.stringify(activeCompany));

      return {
        ...state,
        activePaymentArrangement: activePaymentArrangement,
        activeCompany: activeCompany,
      };
    }

    case SET_ACTIVE_COMPANY: {
      localStorage.setItem(STORAGE_ITEM_ACTIVE_COMPANY, JSON.stringify(action.payload));
      return {
        ...state,
        activeCompany: action.payload
      };
    }

    case GET_ACTIVE_COMPANY_DATA: {
      return {
        ...state,
        activeCompanyData: action.payload,
      };
    }

    case GET_FRESHWORKS_CONFIGURATION: {
      return {
        ...state,
        freshworksConfiguration: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
