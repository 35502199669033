import React, { useState, useMemo } from 'react';
import { Popover, Typography } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import EvomUtils from 'util/EvomUtils';
import { useSelector } from 'react-redux';

const CompanyBalance = () => {
  const { activeCompanyData } = useSelector(({ auth }) => auth);
  const [popoverAnchorElement, setPopoverAnchorElement] = useState(null);

  const handlePopoverOpen = e => setPopoverAnchorElement(e.currentTarget);
  const handlePopoverClose = () => setPopoverAnchorElement(null);

  const getDiscountPolicyAmount = discountPolicy => !discountPolicy ?
    EvomUtils.formatCentsToReal(activeCompanyData.balancePerDiscountPolicy.NO_DISCOUNT?.amount) :
    EvomUtils.formatCentsToReal(activeCompanyData.balancePerDiscountPolicy[discountPolicy]?.amount);

  const getAmountPerDiscountPolicy = companyDiscountPolicy => {
    if (activeCompanyData?.balancePerDiscountPolicy === undefined || activeCompanyData?.balancePerDiscountPolicy === null) return [];
    return Object.keys(activeCompanyData.balancePerDiscountPolicy)
      .sort()
      .filter(discountPolicy => {
        if (!companyDiscountPolicy && discountPolicy === 'NO_DISCOUNT') return false;
        else return discountPolicy !== companyDiscountPolicy;
      })
      .map(discountPolicy => {
        const formattedDiscountPolicy = activeCompanyData.balancePerDiscountPolicy[discountPolicy]?.discountPolicyLabel;
        const amountInCents = activeCompanyData.balancePerDiscountPolicy[discountPolicy]?.amount;
        const amount = EvomUtils.formatCentsToReal(amountInCents);

        return (
          <Typography key={discountPolicy} variant="body1" gutterBottom style={{ color: '#333' }}>
            {formattedDiscountPolicy}: <span
            className={amountInCents > 0 ? 'text-success' : 'text-danger'}>{amount}</span>
          </Typography>
        );
      });
  };

  const amountPerDiscountPolicy = useMemo(() => getAmountPerDiscountPolicy(activeCompanyData?.discountPolicy), [activeCompanyData]);

  return (
    activeCompanyData?.balancePerDiscountPolicy !== undefined && activeCompanyData?.balancePerDiscountPolicy !== null ?
      <div
        className="d-flex justify-content-center flex-column mr-3"
        style={{ cursor: 'default' }}
        data-testid="balance"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >

        <div className="d-flex align-items-center">
          <div>
            <Typography
              variant="caption"
              style={{ color: '#dedede' }}
              className="mr-1"
            >
              Saldo atual
            </Typography>
          </div>
          {amountPerDiscountPolicy.length > 1 && <HelpOutlineIcon style={{ fontSize: '16px' }}/>}
        </div>

        <Typography
          variant="subtitle2" className="font-weight-bold"
          style={{ color: 'white' }}
        >
          {getDiscountPolicyAmount(activeCompanyData.discountPolicy)}
        </Typography>

        {
          amountPerDiscountPolicy.length > 0 &&
          <Popover
            open={Boolean(popoverAnchorElement)}
            anchorEl={popoverAnchorElement}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            onClose={handlePopoverClose}
            style={{ pointerEvents: 'none' }}
          >
            <div style={{ margin: '.75rem' }}>
              <Typography
                variant="body1"
                className="font-weight-bold"
                style={{ color: '#333' }}
              >
                Outros saldos
              </Typography>
              <hr/>
              {amountPerDiscountPolicy}
            </div>
          </Popover>
        }
      </div> : null
  );
};

export default CompanyBalance;
