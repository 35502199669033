import React from "react";
import { useSelector } from "react-redux";

import VerticalHeaderInfoItem from "app/routes/components/VerticalHeaderInfoItem";
import EvomUtils from "util/EvomUtils";

const BalanceCreditLimitAmount = () => {
  const { activeCompanyData } = useSelector(({ auth }) => auth);
  
  return (
    <>
      {
        activeCompanyData?.rechargeBalanceType === 'POST_PAID'
        ? <VerticalHeaderInfoItem 
            caption="Limite de crédito"
            info={EvomUtils.formatCentsToReal(activeCompanyData?.balanceCreditLimitAmountInCents)}
          />
        : null
      }
    </>
  )
};

export default BalanceCreditLimitAmount;