import React, { useState, useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';
import indigoTheme from 'containers/themes/indigoTheme';
import purpleTheme from 'containers/themes/purpleTheme';
import AppLocale from '../lngProvider';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ResetEvomPassword from 'app/routes/open/ResetEvomPassword';
import { getActiveCompanyData, getFreshworksConfiguration } from 'actions/Auth';
import { getEnableDiscountPolicySystemProperties } from 'actions/SystemPropertiesActions';
import AppLayout from './AppLayout';
import FreshChat from 'react-freshchat';
import EvomUtils from 'util/EvomUtils';
import { ToastContainer } from 'react-toastify';
import { EVOMPASS_URL_SLUG, ROLE_AUTHORITIES } from 'util/EvomConstants';
import { TableCell } from '@material-ui/core';

// FIX https://github.com/gregnb/mui-datatables/issues/1893
const oldRender = TableCell.render;
TableCell.render = function(...args) {
  const [props, ...otherArgs] = args;
  if (typeof props === 'object' && props && 'isEmpty' in props) {
    const { isEmpty, ...propsWithoutEmpty } = props;
    return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
  } else {
    return oldRender.apply(this, args);
  }
};

const App = () => {
  const dispatch = useDispatch();
  const { authUser, activePaymentArrangement, activeCompany, freshworksConfiguration } = useSelector(({ auth }) => auth);
  const [urlSlug, setUrlSlug] = useState(EVOMPASS_URL_SLUG);
  const [freshworksToken, setFreshworksToken] = useState('');

  useEffect(() => {
    const activePaymentArrangementUrlSlug = activePaymentArrangement?.urlSlug;
    const authUserPaymentArrangementUrlSlug = authUser?.paymentArrangementUrlSlug;

    if (activePaymentArrangementUrlSlug) setUrlSlug(activePaymentArrangementUrlSlug);
    if (authUserPaymentArrangementUrlSlug) setUrlSlug(authUserPaymentArrangementUrlSlug);

    if (authUser?.isPaymentArrangementUser) {
      setFreshworksToken('531424f4-45dd-4992-9e75-eb00481c8dbe');
    } else if (freshworksConfiguration?.token) {
      setFreshworksToken(freshworksConfiguration.token);
    }
  }, [activePaymentArrangement, authUser, freshworksConfiguration]);

  // active company fetching
  useEffect(() => {
    if (activeCompany?.companyId) {
      dispatch(getActiveCompanyData(activeCompany.companyId));
    }
  }, [activeCompany]);

  // discount policy fetching
  useEffect(() => {
    if (authUser?.paymentArrangementId) {
      dispatch(getEnableDiscountPolicySystemProperties(authUser.paymentArrangementId));
    }
  }, [authUser, activePaymentArrangement]);

  // freshworks configuration
  useEffect(() => {
    if (!authUser?.isPaymentArrangementUser && !freshworksConfiguration?.token) {
      if (authUser?.paymentArrangementGuiBrCode) {
        dispatch(getFreshworksConfiguration(authUser.paymentArrangementGuiBrCode));
      } else if (activePaymentArrangement?.guiBrCode) {
        dispatch(getFreshworksConfiguration(activePaymentArrangement.guiBrCode));
      }
      setFreshworksToken(freshworksConfiguration?.token);
    }
  }, [authUser, freshworksConfiguration]);

  const theme = EvomUtils.getAuthUserAuthority(authUser) === ROLE_AUTHORITIES.ROLE_PMTARG_ADMIN ? createTheme(purpleTheme) : createTheme(indigoTheme);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale={AppLocale['pt'].locale} messages={AppLocale['pt'].messages}>
        <div className="app-main">
          <Switch>
            <Route exact path="/:paymentArrangementUrlSlug/entrar">
              <SignIn/>
            </Route>

            <Route exact path="/:paymentArrangementUrlSlug/cadastro">
              <SignUp/>
            </Route>

            <Route exact path="/account/reset/finish">
              <ResetEvomPassword/>
            </Route>

            <Route exact path="/signin">
              {authUser ? <Redirect to="/home"/> : <Redirect to={`/${urlSlug}/entrar`}/>}
            </Route>

            <Route exact path="/">
              {authUser ? <Redirect to="/home"/> : <Redirect to={`/${urlSlug}/entrar`}/>}
            </Route>

            <Route path="/*">
              {authUser ? <AppLayout /> : <Redirect to={`/${urlSlug}/entrar`}/>}
            </Route>
          </Switch>

          <ToastContainer/>

          {
            freshworksToken
            ? authUser &&
              <FreshChat
                token={freshworksToken}
                email={authUser ? authUser.email : ''}
                firstName={authUser ? authUser.firstName : ''}
                lastName=""
                externalId={authUser ? authUser.login : ''}
                siteId="EvomPass Backoffice"
                onInit={
                  widget => {
                    widget.user.setProperties({
                      token: freshworksConfiguration ? freshworksConfiguration.token : '',
                      email: authUser ? authUser.email : '',
                      firstName: authUser ? authUser.firstName : '',
                      externalId: authUser ? authUser.login : '',
                      paymentArrangementId: authUser ? authUser.paymentArrangementId : '',
                      paymentArrangementMnemonic: authUser ? authUser.paymentArrangementMnemonic : '',
                      paymentArrangementGuiBrCode: authUser ? authUser.paymentArrangementGuiBrCode : '',
                      companies: authUser && authUser.companies ? JSON.stringify(authUser.companies) : '',
                      authorities: authUser && authUser.authorities ? JSON.stringify(authUser.authorities) : '',
                      isCompanyUser: authUser ? authUser.isCompanyUser : '',
                      isPaymentArrangementUser: authUser ? authUser.isPaymentArrangementUser : '',
                      isEmployerUser: authUser ? authUser.isEmployerUser : '',
                      activated: authUser ? authUser.activated : '',
                    });
                  }
                }
              />
            : null
          }
        </div>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
