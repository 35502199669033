import {
  TOGGLE_COLLAPSED_NAV,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SHOW_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_MESSAGE,
  SIGN_OUT
} from '../constants/ActionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  navCollapsed: false,
  message: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      return {
        ...state,
        navCollapsed: false
      };
    }

    case TOGGLE_COLLAPSED_NAV: {
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      };
    }

    case FETCH_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        message: action.payload,
      };
    }

    case SHOW_ERROR_MESSAGE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        error: '',
        message: '',
      };
    }

    case SIGN_OUT: {
      return INIT_STATE;
    }

    default: {
      return state;
    }
  }
}
