import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  errorNumber: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    lineHeight: 1,
    marginBottom: 30,
  },
}));

const Error404 = ({ showGoBackButton = true }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.root}>
      <Box fontSize={{ xs: 100, sm: 160 }} className={classes.errorNumber}>
        404
      </Box>
      <Box fontSize={{ xs: 16, sm: 24 }} mb={4} color="grey.500">
        Oops! Página não encontrada 😢
      </Box>
      {
        showGoBackButton &&
        <Box>
          <Button startIcon={<ArrowBackIcon />} variant="outlined" color="primary" onClick={() => history.push('/home')}>
            voltar
          </Button>
        </Box>
      }
    </Box>
  );
};

export default Error404;
