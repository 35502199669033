import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Link, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import { signIn } from 'actions/Auth';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { TextField, Button, CircularProgress, InputAdornment, IconButton } from '@material-ui/core';
import { useGetPaymentArrangementInfo } from 'hooks/api/paymentArrangement';
import LoadingView from 'app/routes/components/LoadingView';
import ForgotMyPasswordDialog from 'containers/ForgotMyPasswordDialog';
import Page404 from 'app/routes/Page404';

const SignIn = () => {
  const { paymentArrangementUrlSlug } = useParams();
  const { data: paymentArrangementInfo, isLoading, isError } = useGetPaymentArrangementInfo(paymentArrangementUrlSlug);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { loading } = useSelector(({ common }) => common);
  const [showPassword, setShowPassword] = useState(false);
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);

  const handleClickShowPassword = () => {
    if (!showPassword) {
      setShowPassword(true);
    } else { setShowPassword(false); }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const doLogin = (e) => {
    e.preventDefault();
    dispatch(signIn({ email, password }));
  }

  if (authUser) return (
    <Redirect to="/home"/>
  );

  if (isLoading) return (
    <LoadingView/>
  );

  if (isError) return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <Page404 showGoBackButton={false}/>
    </div>
  );

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <img
            alt="Logo"
            width="100%"
            title={paymentArrangementInfo.merchantName}
            src={paymentArrangementInfo.logoUrl}
          />
        </div>

        <div className="app-login-content">
          <form className="h-100 d-flex flex-column justify-content-between" onSubmit={doLogin}>
            <h1 className="mb-0">
              <IntlMessages id="appModule.welcome"/>
            </h1>

            <div>
              <TextField
                label={<IntlMessages id="appModule.login"/>}
                fullWidth
                onChange={event => setEmail(event.target.value)}
                defaultValue={email}
                margin="normal"
                required
              />

              <div>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  label={<IntlMessages id="appModule.password"/>}
                  fullWidth
                  onChange={event => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  required
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                      </InputAdornment>
                  }}
                />
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div className="d-flex flex-column align-items-start">
                <Button size="small" color="primary" onClick={() => setShowResetPasswordDialog(true)}>
                  Esqueci minha senha
                </Button>

                {
                  paymentArrangementInfo.allowEmployerSelfRegistration &&
                  <Button color="primary" size="small" component={Link} to={`/${paymentArrangementUrlSlug}/cadastro`}>
                    Novo usuário? Cadastra-se!
                  </Button>
                }
              </div>

              <Button color="primary" variant="contained" className="ml-auto" type="submit" disabled={loading}>
                <IntlMessages id="appModule.signIn"/>
              </Button>
            </div>
          </form>
        </div>
      </div>

      {
        loading &&
        <div className="loader-view">
          <CircularProgress />
        </div>
      }

      <ForgotMyPasswordDialog
        showResetPasswordDialog={showResetPasswordDialog}
        setShowResetPasswordDialog={setShowResetPasswordDialog}
      />
    </div>
  );
};

export default SignIn;
