import {

  SIGN_OUT,

  GET_POS_USERS,
  CREATE_POS_USER,
  UPDATE_POS_USER,

  SHOW_UPDATE_POS_USER_FORM,
  SHOW_CREATE_POS_USER_FORM,
  HIDE_UPDATE_POS_USER_FORM,
  HIDE_CREATE_POS_USER_FORM,

} from "constants/ActionTypes";

const INIT_STATE = {
  posUsers: {
    data: [],
    count: 0
  },
  posUserUpdating: {
    isUpdatingAnPosUser: false,
    currentUpdatingPosUser: null
  },
  posUserCreation: {
    isCreatingAnPosUser: false,
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_POS_USERS: {
      return {
        ...state,
        posUsers: action.payload,
      };
    }

    case CREATE_POS_USER: {
      const newPosUser = action.payload;

      return {
        ...state,
        posUsers: {
          ...state.posUsers,
          data: [
            newPosUser,
            ...state.posUsers.data
          ],
          count: state.posUsers.count + 1
        }
      };
    }

    case UPDATE_POS_USER: {
      const updatedPosUser = action.payload;

      const posUserIndex = state.posUsers.data.findIndex(posUser =>
        posUser.operatorId === updatedPosUser.operatorId);

      const newPosUsers = state.posUsers.data.map((posUser, index) => {
        if (index === posUserIndex) {
          return updatedPosUser;
        }
        return posUser;
      });

      return {
        ...state,
        posUsers: {
          ...state.posUsers,
          data: newPosUsers
        }
      };
    }

    case SHOW_UPDATE_POS_USER_FORM: {
      const posUserId = action.payload;

      const currentUpdatingPosUser = state.posUsers.data.find(posUser =>
        posUser.operatorId === posUserId);

      return {
        ...state,
        posUserUpdating: {
          isUpdatingAnPosUser: true,
          currentUpdatingPosUser: currentUpdatingPosUser
        },
      };
    }

    case SHOW_CREATE_POS_USER_FORM: {
      return {
        ...state,
        posUserCreation: {
          isCreatingAnPosUser: true,
        },
      };
    }

    case HIDE_UPDATE_POS_USER_FORM: {
      return {
        ...state,
        posUserUpdating: {
          isUpdatingAnPosUser: false,
          currentUpdatingPosUser: null
        },
      };
    }

    case HIDE_CREATE_POS_USER_FORM: {
      return {
        ...state,
        posUserCreation: {
          isCreatingAnPosUser: false,
        },
      };
    }

    case SIGN_OUT: {
      return INIT_STATE;
    }

    default: {
      return state;
    }

  }
}
