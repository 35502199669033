import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

const DiscountPolicy = () => {
  const { authUser, activeCompanyData } = useSelector(({ auth }) => auth);
  const enableDiscountPolicy = useSelector(({ systemProperties }) => systemProperties.enableDiscountPolicy);

  return (
    (enableDiscountPolicy.enableDiscountPolicyFinancial || enableDiscountPolicy.enableDiscountPolicyFare) &&
    activeCompanyData?.discountPolicy &&
    authUser?.isCompanyUser ?
    <div className="d-flex justify-content-center flex-column mr-3">
      <Typography
        variant="caption"
        style={{ color: '#dedede' }}
      >
        Política de desconto
      </Typography>

      <Typography variant="subtitle2" className="font-weight-bold" style={{ color: 'white' }}>
        Desconto {activeCompanyData?.discountPolicyLabel?.toLowerCase()}
      </Typography>
    </div> : null
  );
};

export default DiscountPolicy;
