import React from 'react';
import { Typography } from '@material-ui/core';

const VerticalHeaderInfoItem = ({caption, info}) => {
  return (
    info !== null || info !== undefined
      ? <div className="d-flex justify-content-center flex-column mr-3">
          <Typography
            variant="caption"
            style={{ color: '#dedede' }}
          >
            {caption}
          </Typography>

          <Typography variant="subtitle2" className="font-weight-bold" style={{ color: 'white' }}>
            {info}
          </Typography>
        </div>
      : null
  );
};

export default VerticalHeaderInfoItem;
