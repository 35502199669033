import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveCompany } from 'actions/Auth';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LocationCityIcon from '@material-ui/icons/LocationCity';

const ActiveEmployerController = () => {
  const dispatch = useDispatch();
  const { activePaymentArrangement, activeCompany, authUser } = useSelector(({ auth }) => auth);
  const [companiesMenuAnchorEl, setCompaniesMenuAnchorEl] = useState(null);
  const activePaymentArrangementCompanies = activePaymentArrangement?.companies ?? [];

  const openCompaniesMenu = e => setCompaniesMenuAnchorEl(e.currentTarget);
  const closeCompaniesMenu = () => setCompaniesMenuAnchorEl(null);

  const handleChangeActiveCompany = activeCompany => {
    dispatch(setActiveCompany(activeCompany));
    closeCompaniesMenu();
  };

  const captionContent = authUser?.isPOSAgencyUser ? 'Agência' : 'Empregador';

  return (
    activePaymentArrangementCompanies.length > 1 ?
      <>
        <div className="d-flex justify-content-center flex-column">
          <Typography variant="caption" style={{ color: '#dedede' }}>
            {captionContent}
          </Typography>

          <Tooltip title="Alterar empregador">
            <Button
              style={{ color: 'white' }}
              aria-label="change company"
              aria-controls="change company"
              aria-haspopup="true"
              size="small"
              endIcon={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <BusinessIcon fontSize="small" className="mr-2"/>
                  <KeyboardArrowDownIcon style={{
                    transform: Boolean(companiesMenuAnchorEl) ? 'rotate(180deg)' : '',
                    transition: 'all .4s'
                  }}/>
                </span>
              }
              onClick={openCompaniesMenu}
            >
              <Typography variant="subtitle2" className="font-weight-bold" style={{ margin: '0px', color: 'white' }}>
                {activeCompany?.companyName}
              </Typography>
            </Button>
          </Tooltip>
        </div>

        <Menu
          elevation={6}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          id="customized-menu"
          anchorEl={companiesMenuAnchorEl}
          keepMounted
          open={Boolean(companiesMenuAnchorEl)}
          onClose={closeCompaniesMenu}
        >
          {
            activePaymentArrangementCompanies.map(company =>
              <MenuItem key={company.companyId} onClick={() => handleChangeActiveCompany(company)}>
                <ListItemIcon>
                  <LocationCityIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText primary={company.companyName}/>
              </MenuItem>
            )
          }
        </Menu>
      </> :
      <div className="d-flex justify-content-center flex-column">
        <Typography variant="caption" style={{ color: '#dedede' }}>
          {captionContent}
        </Typography>

        <Typography variant="subtitle2" className="font-weight-bold" style={{ margin: '0px', color: 'white' }}>
          {activeCompany?.companyName?.toUpperCase()}
        </Typography>
      </div>
  );
};

export default ActiveEmployerController;
