import {
  SIGN_OUT,
  GET_COMPANIES,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  CHECK_DUPLICATED_CNPJ,
  SHOW_UPDATE_COMPANY_FORM,
  SHOW_CREATE_COMPANY_FORM,
  SHOW_DELETE_COMPANY_DIALOG,
  HIDE_UPDATE_COMPANY_FORM,
  HIDE_CREATE_COMPANY_FORM,
  HIDE_DELETE_COMPANY_DIALOG,
  HIDE_DUPLICATED_CNPJ_DIALOG,
} from "constants/ActionTypes";

const INIT_STATE = {
  companies: {
    data: [],
    count: 0
  },
  companyUpdating: {
    isUpdatingAnCompany: false,
    currentUpdatingCompany: null,
    isCheckingDuplicatedCnpj: false,
    isCnpjDuplicated: false,
  },
  companyCreation: {
    isCreatingAnCompany: false,
    currentCreatingCompany: null,
    isCheckingDuplicatedCnpj: false,
    isCnpjDuplicated: false,
  },
  companyDeletion: {
    isDeletingAnCompany: false,
    currentDeletingCompany: null
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_COMPANIES: {
      return {
        ...state,
        companies: action.payload,
      };
    }

    case CREATE_COMPANY: {
      const newCompany = action.payload;

      return {
        ...state,
        companies: {
          ...state.companies,
          data: [
            newCompany,
            ...state.companies.data,
          ],
          count: state.companies.count + 1
        }
      };
    }

    case UPDATE_COMPANY: {
      const updatedCompany = action.payload;
      const newCompanies = state.companies.data.map(company =>
        company.id === updatedCompany.id ?
          updatedCompany :
          company
      );

      return {
        ...state,
        companies: {
          ...state.companies,
          data: newCompanies,
        }
      };
    }

    case DELETE_COMPANY: {
      const companyId = action.payload;
      const newCompanies = state.companies.data.filter(company => company.id !== companyId);

      return {
        ...state,
        companies: {
          ...state.companies,
          data: newCompanies,
          count: state.companies.count - 1
        }
      };
    }
    
    case CHECK_DUPLICATED_CNPJ: {
      const isCnpjDuplicated = action.payload.data;
      const company = action.payload.company;
      const isCreatingNewCompany = action.payload.isCreatingNewCompany;

      if (isCreatingNewCompany) {
        return {
          ...state,
          companyCreation: {
            ...state.companyCreation,
            isCheckingDuplicatedCnpj: true,
            isCnpjDuplicated: isCnpjDuplicated,
            currentCreatingCompany: company
          },
        };
      }

      return {
        ...state,
        companyUpdating: {
          ...state.companyUpdating,
          isCheckingDuplicatedCnpj: true,
          isCnpjDuplicated: isCnpjDuplicated,
          currentUpdatingCompany: company,
        },
      };
    }

    case SHOW_UPDATE_COMPANY_FORM: {
      const companyId = action.payload;
      const currentUpdatingCompany = state.companies.data.find(company => company.id === companyId);

      return {
        ...state,
        companyUpdating: {
          isUpdatingAnCompany: true,
          currentUpdatingCompany: currentUpdatingCompany
        },
      };
    }

    case SHOW_CREATE_COMPANY_FORM: {
      return {
        ...state,
        companyCreation: {
          isCreatingAnCompany: true,
        },
      };
    }

    case SHOW_DELETE_COMPANY_DIALOG: {
      const companyId = action.payload;
      const currentDeletingCompany = state.companies.data.find(company => company.id === companyId);

      return {
        ...state,
        companyDeletion: {
          isDeletingAnCompany: true,
          currentDeletingCompany: currentDeletingCompany
        },
      };
    }

    case HIDE_UPDATE_COMPANY_FORM: {
      return {
        ...state,
        companyUpdating: {
          isUpdatingAnCompany: false,
          currentUpdatingCompany: null
        },
      };
    }

    case HIDE_CREATE_COMPANY_FORM: {
      return {
        ...state,
        companyCreation: {
          isCreatingAnCompany: false,
        },
      };
    }

    case HIDE_DELETE_COMPANY_DIALOG: {
      return {
        ...state,
        companyDeletion: {
          isDeletingAnCompany: false,
          currentDeletingCompany: null
        },
      };
    }

    case HIDE_DUPLICATED_CNPJ_DIALOG: {
      return {
        ...state,
        companyCreation: {
          isCreatingAnCompany: false,
          isCnpjDuplicated: false,
          currentCreatingCompany: null
        },
        companyUpdating: {
          isUpdatingAnCompany: false,
          isCnpjDuplicated: false,
          currentUpdatingCompany: null,
        },
      };
    }

    case SIGN_OUT: {
      return INIT_STATE;
    }

    default: {
      return state;
    }

  }
}
