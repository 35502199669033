import {
  SIGN_OUT,

  GET_COMPANY_EMPLOYEES_GROUP,
  CREATE_COMPANY_EMPLOYEES_GROUP,
  UPDATE_COMPANY_EMPLOYEES_GROUP,
  DELETE_COMPANY_EMPLOYEES_GROUP,

  SHOW_CREATE_COMPANY_EMPLOYEES_GROUP_FORM,
  HIDE_CREATE_COMPANY_EMPLOYEES_GROUP_FORM,
  SHOW_UPDATE_COMPANY_EMPLOYEES_GROUP_FORM,
  HIDE_UPDATE_COMPANY_EMPLOYEES_GROUP_FORM,
  SHOW_DELETE_COMPANY_EMPLOYEES_GROUP_FORM,
  HIDE_DELETE_COMPANY_EMPLOYEES_GROUP_FORM,
} from "constants/ActionTypes";

const INIT_STATE = {
  group: {
    data: [],
    count: 0,
  },
  create: {
    isCreating: false,
  },
  update: {
    isUpdating: false,
    currentUpdating: null,
  },
  delete: {
    isDeleting: false,
    currentDeleting: null,
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_EMPLOYEES_GROUP: {
      return {
        ...state,
        group: action.payload,
      };
    }

    case CREATE_COMPANY_EMPLOYEES_GROUP: {
      const newGroup = action.payload;

      return {
        ...state,
        group: {
          ...state.group,
          data: [
            newGroup,
            ...state.group.data
          ],
          count: state.group.count + 1
        }
      };
    }

    case UPDATE_COMPANY_EMPLOYEES_GROUP: {
      const updatedGroup = action.payload;

      return {
        ...state,
        group: {
          ...state.group,
          data: state.group.data.map(
            group => group?.id === updatedGroup?.id ? updatedGroup : group
          )
        }
      };
    }

    case DELETE_COMPANY_EMPLOYEES_GROUP: {
      const deletedGroupId = action.payload;
      return {
        ...state,
        group: {
          ...state.group,
          data: state.group.data.filter(
            group => group?.id !== deletedGroupId
          ),
          count: state.group.count - 1,
        }
      }
    }

    case SHOW_CREATE_COMPANY_EMPLOYEES_GROUP_FORM: {
      return {
        ...state,
        create: {
          ...state.create,
          isCreating: true,
        }
      };
    }

    case HIDE_CREATE_COMPANY_EMPLOYEES_GROUP_FORM: {
      return {
        ...state,
        create: {
          ...state.create,
          isCreating: false,
        }
      };
    }

    case SHOW_UPDATE_COMPANY_EMPLOYEES_GROUP_FORM: {
      const currentUpdatingId = action.payload;

      return {
        ...state,
        update: {
          ...state.update,
          isUpdating: true,
          currentUpdating: state.group.data.find(
            group => group?.id === currentUpdatingId
          )
        }
      };
    }

    case HIDE_UPDATE_COMPANY_EMPLOYEES_GROUP_FORM: {
      return {
        ...state,
        update: {
          ...state.update,
          isUpdating: false,
          currentUpdating: null,
        }
      };
    }

    case SHOW_DELETE_COMPANY_EMPLOYEES_GROUP_FORM: {
      const currentDeletingId = action.payload;
      return {
        ...state,
        delete: {
          ...state.delete,
          isDeleting: true,
          currentDeleting: state.group.data.find(
            group => group?.id === currentDeletingId
          )
        }
      };
    }

    case HIDE_DELETE_COMPANY_EMPLOYEES_GROUP_FORM: {
      return {
        ...state,
        delete: {
          ...state.delete,
          isDeleting: false,
          currentDeleting: null,
        }
      };
    }

    case SIGN_OUT: {
      return INIT_STATE;
    }
  
    default: {
      return state;
    }
  }
};