import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import UserInfo from 'components/UserInfo';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION} from 'constants/ActionTypes';
import {toggleCollapsedNav, updateWindowWidth} from 'actions/Setting';
import SideBarContent from "./SideBarContent";

const SideBar = () => {
  const dispatch = useDispatch();
  const { drawerType, navigationStyle } = useSelector(({settings}) => settings);
  const {navCollapsed} = useSelector(({common}) => common);

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth))
    });
  }, [dispatch]);

  const onToggleCollapsedNav = (e) => {
    dispatch(toggleCollapsedNav());
  };

  let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
  const type = navigationStyle === HORIZONTAL_NAVIGATION ? 'temporary' : drawerType === FIXED_DRAWER ? 'permanent' : 'temporary';

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = '';
  }

  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer className="app-sidebar-content"
                variant={type}
                open={type === 'temporary' ? navCollapsed : true}
                onClose={onToggleCollapsedNav}
                classes={{
                  paper: 'side-nav',
                }}
      >
        <UserInfo/>
        <SideBarContent/>
      </Drawer>
    </div>
  );
};

export default withRouter(SideBar);
