import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

const PhoneNumberInput = ({ defaultValue, ...textFieldProps }) => {
  const [phoneNumber, setPhoneNumber] = useState(defaultValue ?? null);

  return (
      <InputMask
        mask={phoneNumber?.length === 19 ? '+55 (99) 99999-9999' : '+55 (99) 9999-9999'}
        maskChar=""
        onInput={e => {
          if (e.target.value.length <= 19) setPhoneNumber(e.target.value);
          if (e.target.value.length === 13 && e.nativeEvent.inputType === 'deleteContentBackward') setPhoneNumber(e.target.value.substring(0, 12));
          if (e.target.value.length === 8 && e.nativeEvent.inputType === 'deleteContentBackward') setPhoneNumber(e.target.value.substring(0, 6));
        }}
        beforeMaskedValueChange={
          (newState, oldState) => {
            const value = newState.value;
            const selection = oldState?.selection?.start === 18 && oldState?.selection?.start === 18 ? { start: 19, end: 19 } : newState.selection;
            return { value, selection };
          }
        }
        value={phoneNumber}
      >
        {renderedInputComponentProps => <TextField {...renderedInputComponentProps} {...textFieldProps}/>}
      </InputMask>
  );
};

export default PhoneNumberInput;
