import React, { useState } from 'react';
import { EMAIL_REGEX_VALIDATION } from 'util/EvomConstants';
import { useMutation } from 'react-query';
import evompassApi from 'util/Api';
import { toast } from 'react-toastify';
import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';

const ForgotMyPasswordDialog = ({ showResetPasswordDialog, setShowResetPasswordDialog }) => {
  const [email, setEmail] = useState('');
  const isEmailValid = EMAIL_REGEX_VALIDATION.test(email) || email === '';
  const { mutate, isLoading } = useMutation(email => evompassApi.post(`account/reset-password/init`, email));

  const resetMyPassword = () => {
    mutate(email, {
      onSuccess: _data => {
        closeResetPasswordDialog();
        toast.success('E-mail enviado com sucesso');
      },
      onError: error => {
        console.log(error);
        toast.error('Não foi possível enviar um e-mail para redefinir a senha, tente novamente mais tarde');
      },
    });
  }

  const closeResetPasswordDialog = () => {
    if (isLoading) return;
    setShowResetPasswordDialog(false);
    setEmail('');
  };

  return (
    <Dialog maxWidth="xs" open={showResetPasswordDialog} onClose={closeResetPasswordDialog}>
      <DialogTitle>
        Redefinir senha
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Forneça seu e-mail e uma mensagem será enviada para que uma nova senha seja definida
        </DialogContentText>

        <TextField
          fullWidth
          margin="dense"
          label="E-mail"
          type="text"
          color="primary"
          onChange={e => setEmail(e.target.value)}
          value={email}
          error={!isEmailValid}
          helperText={!isEmailValid && 'E-mail inválido'}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={closeResetPasswordDialog} disabled={isLoading}>
          Cancelar
        </Button>

        <Button onClick={resetMyPassword} disabled={isLoading || !isEmailValid || email === ''} color="primary">
          {isLoading && <CircularProgress size={20} className="mr-2"/>} Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForgotMyPasswordDialog;
