import React from "react";

const formatErrorResponse = error => {

  if (!error?.response?.data) {
    return 'Erro desconhecido durante a operação';
  }

  const { message, fieldErrors, detail, userEmail, entityName, status } = error.response.data;

  switch (message) {

    case 'error.http.500': {
      if (detail === 'email must not be null or empty') {
        return 'E-mail não pode ser nulo';
      }
      if (detail === 'password must be at least 6 characters long') {
        return 'A senha deve possuir no mínimo 6 caracteres';
      }
      if (detail === 'userLogin is null') {
        return 'Erro de validação';
      }
      if (detail === 'Crew is inconsistent. id=50') {
        return 'Motorista inconsistente';
      }
      if (detail === 'Crew not found.') {
        return 'Motorista não encontrado no servidor';
      }
      if (detail === 'No user was found for this reset key') {
        return 'Link para resetar senha já utilizado';
      }
      if (detail === 'Company user should be from at least one paymentArrangement') {
        return 'Usuário inválido pois não pertence a nenhum arranjo de pagamento';
      }
      if (detail === 'User (ROLE_COMPANY_USER) must have 1 company associated to, at least. numberOfCompanies=0') {
        return 'Usuário inválido pois não possui nenhum empregador';
      }
      if (detail === 'EvomAccount already in use for this company') {
        return 'Login já cadastrado';
      }
      if (detail === 'Usuário está associado a uma Empresa ou Arranjo inativo'){
        return 'Usuário está associado a uma Empresa ou Arranjo inativo'
      }
      if (detail === 'FIREBASE_PHONE already in use.' || detail === 'FIREBASE_GMAIL already in use.' || detail === 'phone already in use.' || detail === 'google.com already in use.' || detail === 'apple.com already in use.' || detail === 'password already in use.'){
        return 'Esse CPF possui um tipo de login diferente da tentativa de cadastro'
      }
      if (detail === 'PHONE_NUMBER_ALREADY_EXISTS' || detail === 'Login with phone number already in use with this document number.'){
        return 'Este número de celular já foi cadastrado em outra conta'
      }
      if (detail === 'EMAIL_ALREADY_EXISTS' || detail === 'Login with email already in use with this document number.'){
        return 'Este email já foi cadastrado em outra conta'
      }
      if (detail === 'Login type not found.'){
        return 'Tipo de login não encontrado'
      }
      if (detail === 'User found with different login type'){
        return 'Usuário já cadastrado com outro tipo de login'
      }
      if (detail === 'Cannot change the document number of an existing EvomAccount.'){
        return 'Não é possível ao criar uma conta, alterar o CPF de uma conta já existent'
      }
      if (typeof detail === 'string' && detail.match(/cielo/ig)) {
        return formatCieloResponse(detail);
      }
      return 'Erro interno do servidor';
    }

    case 'error.http.400': {
      return 'Erro interno - tente novamente mais tarde';
    }

    case 'error.http.401': {
      if (detail === 'Full authentication is required to access this resource') {
        return 'Usuário não autenticado';
      }
      if (detail === 'Access denied.') {
        return 'Usuário sem permissão para operação realizada';
      }
      if (detail === 'Bad credentials' || detail === 'Usuário inexistente ou senha inválida' || detail.includes('No user found with email:')) {
        return 'Login/Senha incorretos';
      }
      if (/User .* was not activated/.test(detail)) {
        return 'Usuário inativado';
      }
      break;
    }

    case 'error.http.403': {
      return 'Usuário sem permissão para operação realizada';
    }

    case 'error.http.404': {
      if (detail.includes('Freshworks Configuration for Payment Arrangement with GuiBrCode')) {
        return 'Token do Freshworks não configurado!';
      }
      break;
    }

    case 'error.entity.not.found': {
      if (entityName === 'crew') {
        return 'Motorista não encontrado no servidor';
      }
      break;
    }

    case 'error.validation': {
      let formattedErrorMessage;
      const invalidFields = new Set();
      fieldErrors.forEach(fieldError => {
        invalidFields.add(fieldError.field);
      });
      formattedErrorMessage = `Erro de validação no(s) campo(s):`;
      invalidFields.forEach(field => formattedErrorMessage += ` ${formatField(field)}`);
      return formattedErrorMessage;
    }

    case 'firebase.user.already.exists': {
      return `Usuário de e-mail ${userEmail} já cadastrado`;
    }

    case 'error.uuidnull': {
      return 'Uuid inválido';
    }

    case 'error.uuiddexists': {
      return 'Motorista com uuid já existente';
    }

    case 'crew.email.cannot.be.changed': {
      return 'Email de motorista não pode ser alterado';
    }

    case 'user.login.already.used': {
      return 'Já existe uma conta com o e-mail utilizado';
    }

    case 'Error calling GerenciaNet to create a banking billet: Error 3500034 - validation_error: O valor total da cobrança deve ser maior ou igual a R$ 5,00.': {
      return 'O valor total da cobrança deve ser maior ou igual a R$ 5,00';
    }

    case 'not.enough.balance': {
      return 'Saldo insuficiente';
    }

    case 'wallet.transaction.amount.must.be.greater.than.zero.to.rollback': {
      return 'O valor do reembolso parcial precisa ser maior do que R$ 0,00';
    }

    case 'wallet.transaction.amount.must.be.greater.than.void.amount.to.rollback': {
      return 'O valor do reembolso parcial não pode ser maior ou igual ao valor da transação'
    }

    case 'wallet.balance.must.be.greater.than.void.amount.to.rollback': {
      return 'O valor escolhido para reembolso é maior do que o saldo da carteira'
    }

    case 'error.companyInvalid': {
        return 'Por favor, realize o login novamente'
    }

    case 'evom.account.validation.documentNumber.duplicated': {
      return 'Já existe uma Evom Account com este número de documento'
    }

    case 'referral.code.duplicated.for.payment.arrangement': {
      return 'O Código de Indicação já existe para este arranjo de pagamento'
    }

    default: {
      if (status === 403) return 'Usuário sem permissão para operação realizada';
      if (detail) return `Erro:\n ${detail.length > 400? `${detail.slice(0, 400)}...` : detail}`;
      return 'Erro desconhecido durante a operação';
    }

  }

}

const formatField = field => {
  switch (field) {
    case 'userEmail':
      return "E-mail";
    default:
      return field;
  }
}

/**
 * Gera a reposta formatada para o erro da api da Cielo. Tenta fazer o match via regex
 * das strings de "Code" e "Message" na mensagem de resposta. Caso não haja matches,
 * coloca strings vazias.
 *
 * @param {string} cieloRes a resposta da Cielo. Vem no formato de uma string (isto é, sem estrutura).
 * @returns {string} a resposta formatada para exibição no toast de erro.
 */
const formatCieloResponse = cieloRes => {
  // Faz match das strings '"Code": ...' e '"Message": ...' porque a mensagem de resposta vem
  // desestruturada. Espera-se que esses dois campos sempre estejam presentes na resposta.
  const cieloRegex = /("Code":\s*.+?),?\s*("Message":\s*.+),?/i;

  // Lista de matches. O índice 0 tem o match global e os demais indices são os subgrupos.
  const matchGroups = cieloRes.match(cieloRegex); 

  return (
    <div>
      Retorno Cielo:
      <br /><br />
      {matchGroups?.[1] ?? ''}
      <br />
      {matchGroups?.[2] ?? ''}
      <br /><br />
      Entre em contato com o suporte da Cielo.
    </div>
  );
}

export default formatErrorResponse;
