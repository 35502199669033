import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePaymentArrangement } from 'actions/Auth';
import { Button, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const PaymentArrangementController = () => {
  const dispatch = useDispatch();
  const { authUser, activePaymentArrangement } = useSelector(({ auth }) => auth);
  const [paymentArrangementsMenuAnchorEl, setPaymentArrangementsMenuAnchorEl] = React.useState(null);

  const openPaymentArrangementsMenu = e => setPaymentArrangementsMenuAnchorEl(e.currentTarget);
  const closePaymentArrangementsMenu = () => setPaymentArrangementsMenuAnchorEl(null);

  const handleChangeActivePaymentArrangement = activePaymentArrangement => {
    dispatch(setActivePaymentArrangement(activePaymentArrangement));
    closePaymentArrangementsMenu();
  };

  return (
    authUser.isCompanyOrEmployerUser ?
      authUser?.paymentArrangements?.length > 1 ?
        <>
          <div className="d-flex justify-content-center align-items-start flex-column">
            <Typography
              variant="caption"
              style={{ color: '#dedede' }}
            >
              Arranjo de pagamento
            </Typography>

            <Tooltip title="Alterar arranjo de pagamento">
              <Button
                style={{ color: 'white' }}
                size="small"
                endIcon={
                  <KeyboardArrowDownIcon style={{
                    transform: Boolean(paymentArrangementsMenuAnchorEl) ? 'rotate(180deg)' : '',
                    transition: 'all .4s'
                  }}/>
                }
                onClick={openPaymentArrangementsMenu}
              >
                <img
                  src={activePaymentArrangement?.logoURLQRPaper ?? require('assets/images/evom_white_logo.png')}
                  alt="Logo"
                  style={{ height: '30px' }}
                />
              </Button>
            </Tooltip>
          </div>

          <Menu
            elevation={6}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            id="customized-menu"
            anchorEl={paymentArrangementsMenuAnchorEl}
            keepMounted
            open={Boolean(paymentArrangementsMenuAnchorEl)}
            onClose={closePaymentArrangementsMenu}
          >
            {
              authUser.paymentArrangements.map(paymentArrangement =>
                <MenuItem
                  key={paymentArrangement.id}
                  onClick={() => handleChangeActivePaymentArrangement(paymentArrangement)}
                >
                  <ListItemText primary={paymentArrangement.mne}/>
                </MenuItem>
              )
            }
          </Menu>
        </> :
        <img
          src={authUser?.paymentArrangements?.[0]?.logoURLQRPaper ?? require('assets/images/evom_white_logo.png')}
          alt="Logo"
          title="Logo"
          style={{ height: '38px' }}
        /> :
      <img
        src={authUser?.paymentArrangementLogoURLQRPaper ?? require('assets/images/evom_white_logo.png')}
        alt="Logo"
        title="Logo"
        style={{ height: '38px' }}
      />
  );
};

export default PaymentArrangementController;
