import {
  SIGN_OUT,
  GET_COMPANY_INVOICES,
  GET_COMPANY_INVOICES_PENDING,
  DEACTIVATE_INVOICE,
  SHOW_DEACTIVATE_INVOICE_DIALOG,
  HIDE_DEACTIVATE_INVOICE_DIALOG,
  SHOW_IMPORT_INVOICE_RECEIPT_ATTACHMENT_DIALOG,
  HIDE_IMPORT_INVOICE_RECEIPT_ATTACHMENT_DIALOG,
} from "constants/ActionTypes";

const INIT_STATE = {
  companyInvoices: {
    data: [],
    count: 0
  },
  companyInvoicesPending: {
    data: [],
    count: 0
  },
  invoiceDeactivation: {
    isDeactivatingAnInvoice: false,
    currentDeactivatingInvoice: null
  },
  invoiceReceiptAttachment: {
    isImportingInvoiceReceiptAttachment: false,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_INVOICES: {
      return {
        ...state,
        companyInvoices: action.payload,
      };
    }
    case GET_COMPANY_INVOICES_PENDING: {
      return {
        ...state,
        companyInvoicesPending: action.payload,
      };
    }

    case DEACTIVATE_INVOICE: {
      const deactivatedInvoice = action.payload;

      const newCompanyInvoices = state.companyInvoices.data.map(invoice => {
        if (invoice.uuid === deactivatedInvoice.uuid) {
          return deactivatedInvoice;
        } else {
          return invoice;
        }
      });

      return {
        ...state,
        companyInvoices: {
          ...state.companyInvoices,
          data: newCompanyInvoices
        }
      }
    }

    case SHOW_DEACTIVATE_INVOICE_DIALOG: {
      const currentDeactivatingInvoiceId = action.payload;
      const currentDeactivatingInvoice = state.companyInvoices.data.find(invoice => invoice.uuid === currentDeactivatingInvoiceId);

      return {
        ...state,
        invoiceDeactivation: {
          isDeactivatingAnInvoice: true,
          currentDeactivatingInvoice: currentDeactivatingInvoice
        }
      }
    }

    case HIDE_DEACTIVATE_INVOICE_DIALOG: {
      return {
        ...state,
        invoiceDeactivation: {
          isDeactivatingAnInvoice: false,
          currentDeactivatingInvoice: null
        }
      }
    }

    case SHOW_IMPORT_INVOICE_RECEIPT_ATTACHMENT_DIALOG: {
      return {
        ...state,
        invoiceReceiptAttachment: {
          ...state.invoiceReceiptAttachment,
          isImportingInvoiceReceiptAttachment: true,
        }
      }
    }

    case HIDE_IMPORT_INVOICE_RECEIPT_ATTACHMENT_DIALOG: {
      return {
        ...state,
        invoiceReceiptAttachment: {
          ...state.invoiceReceiptAttachment,
          isImportingInvoiceReceiptAttachment: false,
        }
      }
    }

    case SIGN_OUT: {
      return INIT_STATE;
    }

    default: {
      return state;
    }

  }
};
