import {
  SIGN_OUT,
  FETCHING_TOKENS,
  GET_COMPANY_EMPLOYEES,
  CREATE_COMPANY_EMPLOYEE,
  UPDATE_COMPANY_EMPLOYEE,
  GET_TOKENS,
  GENERATE_TOKEN,
  BATCH_GENERATE_TOKEN,
  UPDATE_TOKEN,
  SHOW_QR_CODE,
  SHOW_BATCH_QR_CODE,
  HIDE_QR_CODE,
  HIDE_BATCH_QR_CODE,
  SHOW_UPTADE_TOKEN_DIALOG,
  HIDE_UPDATE_TOKEN_DIALOG,
  SHOW_UPDATE_COMPANY_EMPLOYEE_FORM,
  SHOW_CREATE_COMPANY_EMPLOYEE_FORM,
  HIDE_UPDATE_COMPANY_EMPLOYEE_FORM,
  HIDE_CREATE_COMPANY_EMPLOYEE_FORM,
} from "constants/ActionTypes";

const INIT_STATE = {
  companyEmployees: {
    data: [],
    count: 0
  },
  companyEmployeeUpdating: {
    isUpdatingAnCompanyEmployee: false,
    currentUpdatingCompanyEmployee: null
  },
  companyEmployeeCreation: {
    isCreatingAnCompanyEmployee: false,
  },
  loadingTokens: {},
  tokens: {},
  generatedTokens: [],
  companyWalletsInfo: [],
  batchTokens: {},
  tokenDeactivation: {
    isDeactivatingAnToken: false,
    currentTokenBeingDeactivated: null,
    deactivatingTokenWallet: null
  },
  shownQRCode: null,
  shownQRcodeEmployee: null,
  showQRCodeInBatch: false,
  isLoadingBatch: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_COMPANY_EMPLOYEES: {
      return {
        ...state,
        companyEmployees: action.payload,
      };
    }

    case CREATE_COMPANY_EMPLOYEE: {
      const newCompanyEmployee = action.payload;

      return {
        ...state,
        companyEmployees: {
          ...state.companyEmployees,
          data: [
            newCompanyEmployee,
            ...state.companyEmployees.data
          ],
          count: state.companyEmployees.count + 1
        }
      };
    }

    case UPDATE_COMPANY_EMPLOYEE: {
      const updatedCompanyEmployee = action.payload;
      const { data } = state.companyEmployees;

      const companyEmployeeIndex = data.findIndex(companyEmployee =>
        companyEmployee.walletUuid === updatedCompanyEmployee.walletUuid);

      const newCompanyEmployees = data.map((companyEmployee, index) => {
        if (index === companyEmployeeIndex) {
          return updatedCompanyEmployee;
        }
        return companyEmployee;
      });

      return {
        ...state,
        companyEmployees: {
          ...state.companyEmployees,
          data: newCompanyEmployees,
        }
      };
    }

    case SHOW_UPDATE_COMPANY_EMPLOYEE_FORM: {
      const companyEmployeeId = action.payload;
      const { data } = state.companyEmployees;

      const currentUpdatingCompanyEmployee = data.find(companyEmployee =>
        companyEmployee.walletUuid === companyEmployeeId);

      return {
        ...state,
        companyEmployeeUpdating: {
          isUpdatingAnCompanyEmployee: true,
          currentUpdatingCompanyEmployee: currentUpdatingCompanyEmployee
        },
      };
    }

    case SHOW_CREATE_COMPANY_EMPLOYEE_FORM: {
      return {
        ...state,
        companyEmployeeCreation: {
          isCreatingAnCompanyEmployee: true,
        },
      };
    }

    case HIDE_UPDATE_COMPANY_EMPLOYEE_FORM: {
      return {
        ...state,
        companyEmployeeUpdating: {
          isUpdatingAnCompanyEmployee: false,
          currentUpdatingCompanyEmployee: null
        },
      };
    }

    case HIDE_CREATE_COMPANY_EMPLOYEE_FORM: {
      return {
        ...state,
        companyEmployeeCreation: {
          isCreatingAnCompanyEmployee: false,
        },
      };
    }

    case GET_TOKENS: {
      const { tokens, companyEmployee } = action.payload;

      tokens.sort((a, b) => {
        if (!a.expiryDate) {
          return -1;
        }
        if (!b.expiryDate) {
          return 1;
        }
        return new Date(b.expiryDate) - new Date(a.expiryDate);
      });

      return {
        ...state,
        loadingTokens: {
          ...state.loadingTokens,
          [companyEmployee]: false
        },
        tokens: {
          ...state.tokens,
          [companyEmployee]: tokens
        },
      };
    }

    case GENERATE_TOKEN: {
      const { token, companyEmployee } = action.payload;

      return {
        ...state,
        tokens: {
          ...state.tokens,
          [companyEmployee]: [
            ...state.tokens[companyEmployee],
            token
          ]
        },
      };
    }

    case UPDATE_TOKEN: {
      const { updatedToken, companyEmployee } = action.payload;
      const newTokens = state.tokens[companyEmployee].map(token => token.identificationHash === updatedToken.identificationHash ? updatedToken : token);

      return {
        ...state,
        tokens: {
          ...state.tokens,
          [companyEmployee]: newTokens
        },
      };
    }

    case BATCH_GENERATE_TOKEN: {
      const { tokens, walletsInfo } = action.payload;

      const batchTokens = [tokens, walletsInfo].reduce((acc, el) => {
        for (let key in el) {
          acc[key] = Object.assign({walletUuid: key}, tokens[key], walletsInfo[key]);
        };
        return acc;
      }, {})

      const map = new Map(Object.entries(batchTokens));
      const tokensGenerated = [];
      map.forEach(element => {
        if (element.tokenGenerated) {
          tokensGenerated.push({
            walletUuid: element.walletUuid,
            payload: element.payloadQRCode
          });
        }
      });

      return {
        ...state,
        generatedTokens: tokensGenerated,
        batchTokens: batchTokens,
      };
    }

    case SHOW_UPTADE_TOKEN_DIALOG: {
      const [wallet, token] = action.payload;

      return {
        ...state,
        tokenDeactivation: {
          isDeactivatingAnToken: true,
          currentTokenBeingDeactivated: token,
          deactivatingTokenWallet: wallet
        },
      };
    }

    case HIDE_UPDATE_TOKEN_DIALOG: {
      return {
        ...state,
        tokenDeactivation: {
          isDeactivatingAnToken: false,
          currentTokenBeingDeactivated: null,
          deactivatingTokenWallet: null
        },
      };
    }

    case FETCHING_TOKENS: {
      const companyEmployee = action.payload;

      return {
        ...state,
        loadingTokens: {
          ...state.loadingTokens,
          [companyEmployee]: true
        }
      };
    }

    case SHOW_QR_CODE: {
      const { token, companyEmployee } = action.payload;
      return {
        ...state,
        shownQRCode: token,
        shownQRcodeEmployee: companyEmployee
      };
    }

    case SHOW_BATCH_QR_CODE: {
      return {
        ...state,
        showQRCodeInBatch: true,
        companyWalletsInfo: action.payload,
      }
    }

    case HIDE_QR_CODE: {
      return {
        ...state,
        shownQRCode: null,
        shownQRcodeEmployee: null
      };
    }
    
    case HIDE_BATCH_QR_CODE: {
      return {
        ...state,
        showQRCodeInBatch: false,
        companyWalletsInfo: [],
        generatedTokens: [],
        batchTokens: {},
      }
    }

    case SIGN_OUT: {
      return INIT_STATE;
    }

    default: {
      return state;
    }

  }
}
